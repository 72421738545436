const defaultSystemMessage = {
  id: null,
  base: '',
  type: '',
  data: null,
  userId: null,
  dialogId: null,
};

const getData = (str) => {
  try {
    return JSON.parse(str);
  } catch (_) {
    return null;
  }
};

export default class SystemMessage {
  constructor(message = defaultSystemMessage) {
    this.id = message.id;
    this.base = message.base;
    this.type = message.type || '';
    this.data = getData(message.body);
    this.userId = message.userId;
    this.dialogId = message.extension && message.extension.dialog_id;
  }
}
