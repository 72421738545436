import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 15,
    justifyContent: 'space-between',
  },

  productInformation: {
    width: 0,
    flexGrow: 1,
    paddingRight: 15,
  },

  price: {
    paddingTop: 10,
    fontSize: 18,
  },

  minimumOrder: {
    textAlign: 'right',
    fontSize: 12,
  },
});
