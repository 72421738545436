/* eslint-disable no-bitwise, no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const baseStyles = {
  width: '100%',
  margin: 8,
  boxSizing: 'border-box',
  border: 'none',
  borderRadius: 3,
  resize: 'none',
  fontSize: 15,
  lineHeight: '22px',
  overflow: 'auto',
  height: 'auto',
};

const textareaLineHeight = 22;

const handleChange = ({
  setRows,
  onChangeText,
  enableScrollToCaret,
  minHeight,
  maxHeight,
}) => (event) => {
  const minRows = Math.floor(minHeight / textareaLineHeight);
  const maxRows = Math.floor(maxHeight / textareaLineHeight);

  const previousRows = event.target.rows;
  event.target.rows = minRows; // reset number of rows in textarea

  const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);

  if (currentRows === previousRows) {
    event.target.rows = currentRows;
  }

  if (enableScrollToCaret && currentRows >= maxRows) {
    event.target.rows = maxRows;
    event.target.scrollTop = event.target.scrollHeight;
  }

  onChangeText(event.target.value);
  setRows(currentRows < maxRows ? currentRows : maxRows);
};

const onEnterPress = ({
  sendMessage,
  clearInputFn,
  setRows,
  minHeight,
}) => (e) => {
  if (e.keyCode === 13 && e.shiftKey === false) {
    e.preventDefault();
    sendMessage();
    clearInputFn();
    setRows(minHeight / textareaLineHeight);
    e.target.rows = 0;
    e.target.value = '';
  }
};

const AutoGrowInput = ({
  placeholder,
  value,
  onChangeText,
  maxHeight,
  minHeight,
  sendMessage,
  clearInputFn,
  enableScrollToCaret,
  style,
}) => {
  const [rows, setRows] = useState(minHeight / textareaLineHeight);
  const onHandleChange = handleChange({
    setRows,
    onChangeText,
    enableScrollToCaret,
    minHeight,
    maxHeight,
  });

  return (
    <textarea
      rows={rows}
      value={value}
      placeholder={placeholder}
      onChange={onHandleChange}
      onKeyDown={onEnterPress({
        sendMessage,
        onHandleChange,
        clearInputFn,
        setRows,
        minHeight,
      })}
      style={{ ...baseStyles, ...style }}
    />
  );
};

AutoGrowInput.defaultProps = {
  placeholder: '',
  maxHeight: 170,
  minHeight: 40,
  sendMessage: () => {},
  clearInputFn: () => {},
  enableScrollToCaret: true,
  style: {},
};

AutoGrowInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.number,
  sendMessage: PropTypes.func,
  clearInputFn: PropTypes.func,
  enableScrollToCaret: PropTypes.bool,
  style: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.number,
  ]),
};

export default AutoGrowInput;
