import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import { Back } from '../../Atoms';
import baseStyle from './Header.styles';
import defaultTheme from '../../defaultTheme';

const Header = ({ theme, title, goBack }) => {
  const styles = baseStyle(theme);

  return (
    <View style={styles.container}>
      <SafeAreaView>
        <View style={styles.header}>
          {goBack && (
            <TouchableOpacity onPress={goBack} style={{ zIndex: 2001, cursor: 'pointer' }}>
              <View style={{ position: 'absolute', left: 16, top: 18, width: 50, heigth: 50 }}>
                <Back color={theme.colors.TEXT} width={24} height={24} />
              </View>
            </TouchableOpacity>
          )}
          <View style={styles.text}>
            <Text style={styles.title}>{title}</Text>
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}

Header.defaultProps = {
  theme: defaultTheme,
  title: '',
  goBack: null,
};

Header.propTypes = {
  theme: PropTypes.shape(),
  title: PropTypes.string,
  goBack: PropTypes.func,
};

export default Header;
