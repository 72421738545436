export default ({
  theme,
  selectDialog,
  navigation,
  extraData,
  isGuest,
}) => (dialog) => () => {
  selectDialog(dialog);
  navigation.navigate(
    'Chat',
    {
      extraData: { ...extraData },
      dialog: { ...dialog },
      theme,
      isGuest,
    },
  );
};
