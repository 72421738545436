import CurrentUser from '@netlolo/core-xmpp/src/services/CurrentUserDataService';
import ConnectyCube from '../ConnectyCube';

export default (history, dialogs, sortChats, user) => {
  const userId = CurrentUser.getProp('id') || user.id;
  const rooms = Object.keys(history).map((key) => {
    const { xmpp_type: type, destination } = dialogs.find((item) => item.id === key) || {};

    return ({
      type: type || 'groupchat',
      destination,
      messages: history[key],
    });
  });
  rooms.forEach((room) => {
    room.messages.forEach((message) => {
      const mine = userId === message.sender_id;
      if (!message.sent && mine) {
        const date = Number(Date.now());
        const msg = {
          id: message.id,
          type: room.type,
          body: message.body,
          extension: {
            save_to_history: 1,
            dialog_id: message.dialog_id,
            sender_id: message.sender_id,
            date_sent: date,
          },
          markable: 1,
        };
        ConnectyCube.chat.send(room.destination, msg);
        sortChats(msg);
      }
    });
  });
};
