import React from 'react';
import { View } from 'react-native';
import { Provider } from 'react-redux';
import createStore from '@netlolo/core-redux/src/store';
import RootScreen from '@netlolo/core-chat/src/Screens/Root/Root';
import Main from '@netlolo/core-chat/src/Screens/Main/Main';
import InitChat from './InitChat';

const appConfig = {
  netlolo: {
    connectyCubeConfig: [
      {
        appId: 1622,
        authKey: 'RhTDy7S2ABhHsUV',
        authSecret: 'R6OmMFcMYBfHTtW',
      },
      {
        endpoints: {
          api: 'apizax.connectycube.com',
          chat: 'chatzax.connectycube.com',
        },
        conference: { server: 'wss://januszax.connectycube.com:8989' },
        chat: {
          streamManagement: {
            enable: true,
          },
        },
        debug: {
          mode: 0,
        },
      },
    ],
  },
  zax: {
    connectyCubeConfig: [
      {
        appId: 896,
        authKey: 'QCTzRn8jSJ6rGKd',
        authSecret: 'pWWsm5rfF28WFkR',
      },
      {
        endpoints: {
          api: 'apizax.connectycube.com',
          chat: 'chatzax.connectycube.com',
        },
        conference: { server: 'wss://januszax.connectycube.com:8989' },
        chat: {
          streamManagement: {
            enable: true,
          },
        },
        debug: {
          mode: 0,
        },
      },
    ],
  },
};

const { store } = createStore();

const getId = (str) => {
  const [start = ''] = str.split('@');
  const [_, id] = start.split('_');
  return Number(id);
};

const ChatBase = ({ children, navigation, settings }) => {
  const { user, account } = settings;

  if (!user) {
    return null;
  }

  const password = '123abc!@';

  return (
    <View style={{ flex: 1 }} behavior="padding">
      <Provider store={store}>
        <Main appConfig={appConfig[account] || appConfig.netlolo} email={user} password={password}>
          <InitChat
            Base={children}
            WithChat={(
              <RootScreen
                navigation={navigation}
                AppRouter={children}
                pushService={() => ({ createLocalNotification: () => {} })}
                notificationIOS={{}}
                extraData={{ groupName: 'zaxpro', mine: getId(user) }}
              />
            )}
          />
        </Main>
      </Provider>
    </View>
  );
};

export default ChatBase;
