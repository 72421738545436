const colors = {
  PRIMARY: '#169b0a',
  OTHER_MESSAGE_BG: '#FFFEFC',
  MINE_MESSAGE_BG: '#E2E2E2',
  TEXT: '#333333',
  LIGHT_TEXT: '#FFFFFF',
  TEXT_DETAILS: '#858C92',
  MESSAGE_INPUT_BG: 'whitesmoke',
  DEFAULT_BORDER: 'lightgrey',
  DEFAULT_BG: '#F5F4F2',
  LIGHT_BG: '#FFFFFF',
  STATUS_BAR: '#FFFFFF',
  DISABLED: '#BDC6CF',
};

const mixins = {
  statusBarStyle: 'dark-content',
  loadingSize: 'small',
};

export default {
  colors,
  mixins,
};
