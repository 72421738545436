export default ({ user, xmpp }) => ({
  sender_id: userId,
  id: messageId,
  dialog_id: dialogId,
  read,
}) => {
  const isOtherSender = userId !== user.id;
  if (isOtherSender && (read || []).indexOf(user.id) === -1) {
    const params = { messageId, dialogId, userId: Number(userId) };
    xmpp.chat.sendReadStatus(params);
  }
};
