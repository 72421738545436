import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View, ImageBackground } from 'react-native';
import { Mute } from '../../Atoms';
import SettingsList from '../../Atoms/SettingsList/SettingsList';
import { ProfileIcon } from '../../Molecules';
import styles from './ProfileScreen.styles';
import avatarPlaceholder from '../../../images/avatar-placeholder.png';

const ProfileScreen = ({
  title,
  description,
  occupants,
  muteValue,
  onMuteToggle,
}) => (
  <ScrollView style={styles.container}>
    <ImageBackground style={styles.avatar} source={avatarPlaceholder} />
    <SettingsList borderColor="#c8c7cc" scrollViewProps={styles.wrapper}>
      <SettingsList.Header />
      <SettingsList.Item
        title={title}
        titleStyle={styles.title}
        hasNavArrow={false}
      />
      <SettingsList.Item
        title={description}
        titleStyle={styles.description}
        hasNavArrow={false}
      />

      <SettingsList.Header headerStyle={styles.muteGroup} />
      <SettingsList.Item
        icon={(
          <View style={styles.icon}>
            <Mute width={32} height={32} />
          </View>
        )}
        hasNavArrow={false}
        switchState={muteValue}
        switchOnValueChange={onMuteToggle}
        hasSwitch
        title="Silenciar"
      />

      <SettingsList.Header
        headerText={`${occupants.length} PARTICIPANTE${occupants.length > 1 ? 'S' : ''}`}
        headerStyle={styles.occupantsGroup}
      />
      {occupants.map(({ avatar: userAvatar, full_name: userName, id: userId }) => (
        <SettingsList.Item
          icon={(
            <View style={[styles.icon, styles.avatarIcon]}>
              <ProfileIcon
                photo={userAvatar}
                name={userName}
                iconSize="small"
              />
            </View>
          )}
          title={userName}
          titleStyle={styles.avatarName}
          hasNavArrow={false}
          key={`${userName}-${userId}`}
        />
      ))}
    </SettingsList>
  </ScrollView>
);

ProfileScreen.defaultProps = {
  title: '',
  description: 'Sem descrição',
  occupants: [],
  muteValue: false,
  onMuteToggle: () => {},
};

ProfileScreen.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  occupants: PropTypes.arrayOf(PropTypes.shape()),
  muteValue: PropTypes.bool,
  onMuteToggle: PropTypes.func,
};

export default ProfileScreen;
