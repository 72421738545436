import { StyleSheet } from 'react-native';

export default theme => StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.LIGHT_BG,
  },
  container: {
    flex: 1,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: '10%',
  },
  logo: {
    width: 120,
    height: 120,
  },
  loginContainer: {
    width: '100%',
  },
  welcome: {
    fontSize: 24,
    textAlign: 'center',
    paddingTop: 30,
  },
  inputContainer: {
    paddingVertical: 20,
    width: '100%',
  },
  input: {
    borderBottomWidth: 1,
    borderColor: theme.colors.TEXT,
    padding: 10,
  },
  button: {
    backgroundColor: theme.colors.PRIMARY,
    alignSelf: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 20,
    borderRadius: 8,
  },
  buttonText: {
    color: theme.colors.LIGHT_BG,
    fontSize: 16,
  },



  signupTextContainer: {
    marginTop: 30,
  },
  signupText: {
    fontSize: 14,
    textAlign: 'center',
  },
});
