import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const List = ({
  ListHeaderComponent,
  ListFooterComponent,
  data,
  renderItem,
  onEndReached,
  style,
}) => {
  const [height, setHeight] = useState(374);

  useEffect(() => {
    const el = document.querySelector('[data-testid="DialogScreen"]') || {};
    const value = el.clientHeight || 427;
    setHeight(value - 50);
  }, []);

  return (
    <>
      {ListHeaderComponent}
      <InfiniteScroll
        dataLength={data.length}
        loader={ListFooterComponent}
        next={onEndReached}
        style={style}
        height={height}
        hasMore
      >
        {data.map((item, index) => renderItem({ item, index }))}
      </InfiniteScroll>
    </>
  );
};

export default List;
