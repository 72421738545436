import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import { FlatList, ScrollView, Platform, Dimensions } from 'react-native';
import { Message } from '../../Molecules';
import { DateSeparator } from '../../Atoms';

const realWidth = Dimensions.get('window').width * 0.7;

const checkStatus = (isOtherSender, array = [], userId) => {
  if (isOtherSender) {
    return false;
  }

  return array.filter((id) => id !== userId).length > 0;
};

const getSeries = (history, index, separator) => {
  if (history.length - 1 === index) {
    return true;
  }

  const next = history[index + 1] || {};
  const actual = history[index];

  const isSeries = next.sender_id !== actual.sender_id;
  return isSeries || separator !== null;
};

const getLast = (history, index, isOtherSender, userId) => {
  if (index === 0) {
    return true;
  }

  if (history.length - 1 === index) {
    return false;
  }

  const last = history[index - 1] || null;
  const next = history[index + 1] || null;

  if (next === null) {
    return true;
  }
  if (last === null) {
    const nextIsOtherSender = next.sender_id !== userId;
    if (nextIsOtherSender === isOtherSender) {
      return false;
    }
  }

  const lastIsOtherSender = last.sender_id !== userId;
  return lastIsOtherSender !== isOtherSender;
};

const getDateSeparatorText = (nextDate) => {
  const asMoment = moment(nextDate);

  return asMoment.calendar(null, {
    sameDay: '[Hoje]',
    nextDay: '[Amanhã]',
    nextWeek: 'dddd',
    lastDay: '[Ontem]',
    lastWeek: 'dddd',
    sameElse: moment().diff(asMoment, 'months', true) >= 6 ? 'DD [de] MMM [de] Y' : 'ddd, D [de] MMM',
  }).toLowerCase();
};

const getDateSeparator = (history, index, date) => {
  const messageDay = new Date(date);

  if (history.length - 1 === index) {
    return <DateSeparator text={getDateSeparatorText(messageDay)} />;
  }

  const nextDate = history[index + 1].date_sent;
  const nextMessageDay = new Date(nextDate);
  if (messageDay.getDate() !== nextMessageDay.getDate()) {
    return <DateSeparator text={getDateSeparatorText(messageDay)} />;
  }

  return null;
};

const renderMessageItem = (
  theme,
  userId,
  onPress,
  whats,
  history,
  externalRender,
  bigScreen,
  mediaProgress,
) => ({ item: message, index }) => {
  const isOtherSender = message.sender_id !== userId;
  const read = checkStatus(isOtherSender, message.read, message.sender_id);
  const delivered = checkStatus(isOtherSender, message.delivered, message.sender_id);
  const isLast = whats && getLast(history, index, isOtherSender, userId);
  const dateSeparator = whats && getDateSeparator(history, index, message.date_sent);
  const showSenderName = whats && isOtherSender && getSeries(history, index, dateSeparator);
  const { sender } = message || {};
  const { full_name: name = '' } = sender || {};
  let actualProgress = 1;

  if (message.attachment) {
    const { uid } = message.attachment;
    actualProgress = mediaProgress[uid] || 1;
  }

  return (
    <React.Fragment key={message.id}>
      <Message
        theme={theme}
        otherSender={isOtherSender}
        message={message}
        key={message.id}
        delivered={delivered}
        read={read}
        sent={!isOtherSender && message.sent}
        sender={name}
        showSenderName={showSenderName}
        whatsAppStyle={whats}
        isLast={isLast}
        onPress={onPress}
        externalRender={externalRender}
        realWidth={bigScreen ? realWidth : null}
        actualProgress={actualProgress}
      />
      {dateSeparator}
    </React.Fragment>
  );
};

const styles = {
  flexDirection: 'column-reverse',
  display: 'flex',
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'scroll',
};

const MessageList = ({
  theme,
  history,
  userId,
  onPress,
  whats,
  externalRender,
  mediaProgress,
}) => (
  <>
    {Platform.OS === 'web'
      ? (
        <ScrollView contentContainerStyle={styles}>
          {history.map((item, index) => (
            renderMessageItem(
              theme,
              userId,
              onPress,
              whats,
              history,
              externalRender,
              true,
              mediaProgress,
            )({ item, index })
          ))}
        </ScrollView>
      ) : (
        <FlatList
          inverted
          data={history}
          keyExtractor={(item) => item.id}
          renderItem={renderMessageItem(
            theme,
            userId,
            onPress,
            whats,
            history,
            externalRender,
            false,
            mediaProgress,
          )}
        />
      )}
  </>
);

MessageList.defaultProps = {
  theme: undefined,
  history: [],
  onPress: () => {},
  whats: false,
  externalRender: () => {},
  mediaProgress: {},
};

MessageList.propTypes = {
  theme: PropTypes.shape(),
  history: PropTypes.arrayOf(PropTypes.shape()),
  userId: PropTypes.number.isRequired,
  onPress: PropTypes.func,
  whats: PropTypes.bool,
  externalRender: PropTypes.func,
};

export default MessageList;
