import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { createBrowserApp } from '@react-navigation/web';
import { Provider } from 'react-redux';
import { createStore } from '@netlolo/core-redux';
import Fab from './Base/Fab';
import MainNavigator from './Base/MainNavigator';
import WhatsAppNavigator from './Base/WhatsAppNavigator';
import WhatsAppServiceProvider from './Base/WhatsAppService';

const AppContainer = createBrowserApp(MainNavigator);
const WhatsAppContainer = createBrowserApp(WhatsAppNavigator);
const navigator = React.createRef();

let store;

const App = ({ settings }) => {
  const [fab, setFab] = useState(false);
  if (settings.whatsapp && !store) {
    store = createStore().store;
  }

  return (
    settings.whatsapp
      ? (
        <Provider store={store}>
          <WhatsAppServiceProvider>
            <View
              style={{
                display: fab ? 'flex' : 'none',
                marginLeft: 24,
                marginRight: 12,
                backgroundColor: '#ffffff',
                borderColor: '#cacaca',
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 12,
                boxShadow: '0 4px 4px 0 #C2C2C2',
              }}
            >
              <WhatsAppContainer ref={navigator} settings={settings} />
            </View>
            <View style={{ height: 70, zIndex: 9999 }}>
              <Fab
                startOpen={false}
                isOpen={fab}
                open={() => setFab(true)}
                close={() => setFab(false)}
              />
            </View>
          </WhatsAppServiceProvider>
        </Provider>
      ) : <AppContainer ref={navigator} settings={settings} />
  );
}

App.defaultProps = {
  settings: {
    whatsapp: false,
  },
};

App.propTypes = {
  settings: PropTypes.shape({
    whatsapp: PropTypes.bool,
  }),
};

export default App;
