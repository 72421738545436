import { SET_EXTRA_DATA } from '../Actions/extraData';
import { USER_LOGOUT } from '../Actions/user';

export default (extraData = {}, action) => {
  switch (action.type) {
    case SET_EXTRA_DATA:
      return { ...extraData, ...action.extraData };
    case USER_LOGOUT:
      return {};
    default:
      return extraData;
  }
};
