import React from 'react';
import { connect } from 'react-redux';

export const checkTyping = (dialogId, user, typing) => {
  const users = typing[dialogId] || {};
  const isTyping = Object.keys(users)
    .filter(key => users[key] === true && Number(key) !== user.id);

  return isTyping.length > 0;
};

const Typing = ({
  dialogId,
  text,
  user,
  typing,
}) => (
  checkTyping(dialogId, user, typing) ? text : null
);

const mapStateToProps = (state) => ({
  user: state.user,
  typing: state.typing,
});

export default connect(mapStateToProps)(Typing);
