import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  RefreshControl,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
  ActivityIndicator,
} from 'react-native';
// eslint-disable-next-line atomic-design/hierarchical-import
import ModalDropdown from './ModalDropDown';
// eslint-disable-next-line atomic-design/hierarchical-import
import List from './List';
import { Archieve, Trash } from '../../Atoms/Icon';
import { Dialog } from '../../Organisms';
import baseStyle from './DialogScreen.styles';
import defaultTheme from '../../defaultTheme';

const onSearchChat = (setSearch, setSearchTerm) => (value) => {
  setSearch(value);
  setSearchTerm(value);
};

const FooterButton = ({ theme, icon, text, onPress, loading }) => (
  <TouchableOpacity onPress={onPress} disabled={loading}>
    <View
      style={{
        borderRadius: 5,
        borderColor: theme.colors.PRIMARY,
        backgroundColor: theme.colors.PRIMARY,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingVertical: 12,
        paddingHorizontal: 36,
        marginHorizontal: 6,
        marginTop: 6,
        flexDirection: 'row',
      }}
    >
      {icon}
      <Text style={{ color: theme.colors.LIGHT_TEXT, fontSize: 14, fontWeight: 'bold', marginLeft: 6 }}>
        {text}
      </Text>
    </View>
  </TouchableOpacity>
);

const FooterButtons = ({ theme, edit, onDelete, onArchive, onUnArchive, loading, filter }) => (
  <View
    style={{
      flexDirection: 'row',
      borderTopWidth: 1,
      borderTopColor: 'rgba(136,135,138,0.3)',
      height: edit ? 80 : 0,
      paddingLeft: 6,
    }}
  >
    <FooterButton
      onPress={onDelete}
      theme={theme}
      icon={<Trash color={theme.colors.LIGHT_TEXT} height={24} width={24} />}
      text="Excluir"
      loading={loading}
    />
    {(filter === 'archived'
      ? (
        <FooterButton
          onPress={onUnArchive}
          theme={theme}
          icon={<Archieve color={theme.colors.LIGHT_TEXT} height={24} width={24} />}
          text="Desarquivar"
          loading={loading}
        />
      ) : (
        <FooterButton
          onPress={onArchive}
          theme={theme}
          icon={<Archieve color={theme.colors.LIGHT_TEXT} height={24} width={24} />}
          text="Arquivar"
          loading={loading}
        />
      )
    )}
  </View>
);

const DialogScreen = ({
  theme,
  dialogs,
  toChat,
  checkTyping,
  typingText,
  user,
  typing,
  refreshing,
  onRefresh,
  noChatsText,
  edit,
  checked,
  onCheck,
  onArchive,
  onUnArchive,
  onDelete,
  loading,
  filter,
  onFilter,
  setFilter,
  fetchMore,
  setSearchTerm,
}) => {
  const styles = baseStyle(theme);
  const [search, setSearch] = useState('');
  const [filtredDialogs, setDialogs] = useState(dialogs);
  console.log({ dialogs });

  const filterDialogs = (list) => {
    setDialogs(onFilter(list));
  };

  useEffect(() => {
    filterDialogs(dialogs);
  }, [dialogs, filter]);

  const renderDialog = (edit, loading) => ({ item: dialog, index }) => (
    <Dialog
      key={dialog.id}
      theme={theme}
      id={dialog.id}
      toChat={toChat(dialog)}
      avatar={(dialog.photo || {}).uri}
      unreadCount={dialog.unread_messages_count}
      name={dialog.name}
      lastMessage={dialog.last_message}
      checkTyping={checkTyping(dialog.id, user, typing)}
      typingText={typingText}
      lastDate={dialog.last_message_date_sent}
      index={index}
      edit={edit}
      selected={!!checked.get(dialog.id)}
      onSelect={onCheck}
      loading={loading}
    />
  );

  const renderRefreshControl = () => (
    <RefreshControl
      testID="DialogScreen_Refresh"
      colors={['red', 'green', 'blue']}
      refreshing={refreshing}
      onRefresh={onRefresh}
    />
  );

  return (
    <View style={styles.container}>
      {
        !dialogs.length && (
          <View style={styles.noChats}>
            {(typeof noChatsText === 'string'
              ? <Text style={styles.noChatsText}>{noChatsText}</Text>
              : noChatsText
            )}
          </View>
        )
      }
      <List
        ListHeaderComponent={(
          <View style={styles.search}>
            <View style={{ flex: 0.3 }}>
              <ModalDropdown
                options={['Todas', 'Padrão', 'Lidas', 'Não Lidas', 'Arquivadas']}
                defaultIndex={1}
                defaultValue="Padrão"
                onSelect={setFilter}
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: 8,
                  height: 34,
                  marginRight: 12,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                textStyle={{
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
                dropdownStyle={{
                  width: '30%',
                  height: 203,
                }}
                dropdownTextStyle={{
                  fontSize: 16,
                }}
                dropdownTextHighlightStyle={{
                  fontWeight: 'bold',
                }}
              />
            </View>
            <TextInput
              value={search}
              onChangeText={onSearchChat(setSearch, setSearchTerm)}
              style={styles.searchInput}
              placeholder="Buscar"
            />
          </View>
        )}
        testID="DialogScreen_List"
        data={filtredDialogs}
        keyExtractor={(item) => item.id}
        renderItem={renderDialog(edit, loading)}
        refreshControl={renderRefreshControl()}
        extraData={checked}
        style={{ flex: edit ? 0.9 : 1 }}
        onEndReachedThreshold={Platform.OS === 'android' ? 0.3 : -0.01}
        onEndReached={fetchMore}
        ListFooterComponent={loading && <ActivityIndicator size="small" color="#000000" style={{ marginTop: 25 }} />}
      />
      {Platform.OS !== 'web' && (
        <FooterButtons
          theme={theme}
          edit={edit}
          onArchive={onArchive}
          onUnArchive={onUnArchive}
          onDelete={onDelete}
          loading={loading}
          filter={filter}
        />
      )}
    </View>
  );
};

DialogScreen.defaultProps = {
  theme: defaultTheme,
  dialogs: [],
  user: {},
  typing: {},
  refreshing: false,
  noChatsText: 'Nenhum chat ainda',
  edit: false,
};

DialogScreen.propTypes = {
  theme: PropTypes.shape(),
  dialogs: PropTypes.arrayOf(PropTypes.shape()),
  toChat: PropTypes.func.isRequired,
  checkTyping: PropTypes.func.isRequired,
  typingText: PropTypes.string.isRequired,
  user: PropTypes.shape(),
  typing: PropTypes.shape(),
  refreshing: PropTypes.bool,
  onRefresh: PropTypes.func.isRequired,
  noChatsText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  edit: PropTypes.bool,
};

export default DialogScreen;
