import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { fetchDialogs } from '@netlolo/core-redux/src/Actions/dialogs';
import Chat from '@netlolo/core-xmpp/src/services/ChatService';
import Contacts from '@netlolo/core-xmpp/src/services/ContactsDataService';
import ConnectyCube from '../../Helpers/ConnectyCube';
import ChatScreen from '../ChatScreen/ChatScreen';
import DialogsScreen from '../DialogsScreen/DialogsScreen';

const getId = (str) => {
  const [start = ''] = str.split('@');
  const [_, id] = start.split('_');
  return Number(id);
};

const getUserId = (dialog) => {
  const { occupants_ids: users } = dialog;
  const myChatId = ConnectyCube.service.getCurrentUserId();
  const blacklist = [myChatId, 153393, 1589092];
  const [userId] = users.filter((item) => blacklist.indexOf(item) === -1);
  const { email = '' } = Contacts.get(userId) || {};
  return getId(email);
};

const onSelect = (setPropDialog, setExtraData) => ({ selectDialog, extraData }) => (dialog) => () => {
  const userId = getUserId(dialog);
  setPropDialog(dialog);
  selectDialog(dialog);
  setExtraData({ ...extraData, id: userId });
};

const BigScreen = ({ theme, externalRender, extraData, getChats, set, ...props }) => {
  const [propDialog, setPropDialog] = useState(null);
  const [data, setExtraData] = useState(extraData);

  useEffect(() => {
    const newChatsInterval = setInterval(async () => {
      const session = ConnectyCube.service.getSession();
      if (session) {
        const newDialogs = await Chat.getConversations();
        getChats(newDialogs);
      }
    }, 60 * 1000);

    return () => clearInterval(newChatsInterval);
  }, []);

  return (
    <View style={{ flex: 1, width: '100%', height: '100%', flexDirection: 'row' }}>
      <View style={{ width: '30vw', height: '93vh', borderRightColor: 'lightgrey', borderRightStyle: 'solid', borderRightWidth: 1 }}>
        <DialogsScreen theme={theme} isSelect onSelect={onSelect(setPropDialog, setExtraData)} {...props} />
      </View>
      <View style={{ width: '70vw', height: '93vh' }}>
        <ChatScreen theme={theme} externalRender={externalRender} whats extraData={data} propDialog={propDialog} {...props} />
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  extraData: state.extraData,
});

const mapDispatchToProps = (dispatch) => ({
  getChats: (dialogs) => dispatch(fetchDialogs(dialogs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BigScreen);
