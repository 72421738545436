import React from 'react';
import PropTypes from 'prop-types';
import WhatsAppMain from './WhatsAppMain';
import WhatsAppChat from './WhatsAppChat';

const WhatsAppScren = ({
  theme,
}) => {
  const uuid = 'a49ce83f-28ea-40eb-9c44-c0f786011b8g';

  return (
    <WhatsAppMain user={uuid}>
      <WhatsAppChat theme={theme} />
    </WhatsAppMain>
  );
};

WhatsAppScren.defaultProps = {
  theme: undefined,
};

WhatsAppScren.propTypes = {
  theme: PropTypes.shape(),
};

export default WhatsAppScren;
