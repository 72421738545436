import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import CurrencyFormatter from 'currency-formatter';

export const format = ({ currency, value }, locale = 'pt-BR') => (
  CurrencyFormatter.format(value, { code: currency, locale })
);

const Money = ({ amount, locale }) => (
  <Text>
    {format(amount, locale)}
  </Text>
);

Money.defaultProps = {
  locale: 'pt-BR',
};

Money.propTypes = {
  amount: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  locale: PropTypes.string,
};

export default Money;
