import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, View } from 'react-native';
import baseStyle from './Loader.styles';
import defaultTheme from '../../defaultTheme';

const Loader = ({ theme }) => {
  const styles = baseStyle(theme);

  return (
    <View style={styles.container}>
      <ActivityIndicator
        size="large"
        color={theme.colors.TEXT}
      />
    </View>
  );
};

Loader.defaultProps = {
  theme: defaultTheme,
};

Loader.propTypes = {
  theme: PropTypes.shape(),
};

export default Loader;
