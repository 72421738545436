import AsyncStorage from './AsyncStorage';

class CurrentUserData {
  constructor(user = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._key = this.constructor.name;
    this.user = user;
  }

  set = async (user) => {
    try {
      const value = JSON.stringify(user);
      Object.assign(this.user, user);
      // eslint-disable-next-line no-underscore-dangle
      await AsyncStorage.setItem(this._key, value);
      return null;
    } catch (error) {
      return error;
    }
  };

  get = async () => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      const value = await AsyncStorage.getItem(this._key);
      return value && JSON.parse(value);
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line no-prototype-builtins
  getProp = (prop) => (this.user.hasOwnProperty(prop) && this.user[prop]) || null;
}

// create instance
const CurrentUser = new CurrentUserData();

// lock instance
Object.freeze(CurrentUser);

export default CurrentUser;
