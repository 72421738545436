import AsyncStorage from '@react-native-community/async-storage';

class ContactsCollection {
  constructor(contacts = {}) {
    this.contacts = contacts;
  }

  set = data => {
    const result = Object.assign(this.contacts, data);
    AsyncStorage.setItem('@netlolo/chat-contacts', JSON.stringify(this.contacts));
    return result;
  }

  get = id => (id ? this.contacts[id] : Object.values(this.contacts));
}

// create instance
const Contacts = new ContactsCollection();

// lock instance
Object.freeze(Contacts);

export default Contacts;
