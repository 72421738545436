import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import styles from './DateSeparator.styles';

const DateSeparator = ({ text }) => (
  <View style={styles.container}>
    <View style={styles.bubble}>
      <Text style={styles.text}>{text}</Text>
    </View>
  </View>
);

DateSeparator.propTypes = {
  text: PropTypes.string.isRequired,
};

export default DateSeparator;
