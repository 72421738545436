import { INTERNET_REACHABLE, INTERNET_UNREACHABLE } from '../Actions/internetReachable';

export default (internetReachable = false, action) => {
  switch (action.type) {
    case INTERNET_REACHABLE:
      return true;
    case INTERNET_UNREACHABLE:
      return false;
    default:
      return internetReachable;
  }
};
