import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { OfflineNotice } from '@netlolo/ui-base/src/Atoms/OfflineNotice';
import ChatService from '@netlolo/core-xmpp/src/services/ChatService';
import { fetchDialogs, setSelected, removeSelected } from '@netlolo/core-redux/src/Actions';
import { DialogScreen } from '@netlolo/ui-base/src/Templates';
import { checkTyping } from '../../Helpers/Typing/Typing';
import toChat from './toChat';
import refresh from './refresh';

const DialogsScreen = ({
  // Props
  theme,
  avatar,
  navigation,
  isSelect,
  onSelect,
  noChatsText,
  isGuest,

  // Props from state
  dialogs,
  user,
  typing,
  isConnected,
  isInternetReachable,
  extraData,
  // Actions
  getChats,
  selectDialog,
  clearSelected,
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const [checked, setChecked] = useState(new Map());
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('default');
  const [pagination, setPagination] = useState({ page: 1, lastPage: false });
  const edit = navigation.getParam('edit');
  const onRefresh = refresh({ isConnected, setRefreshing, getChats, setPagination });
  const onToChatPressed = isSelect
    ? onSelect({ selectDialog, extraData })
    : toChat({ theme, selectDialog, navigation, extraData, isGuest });

  const onCheck = React.useCallback(
    (id) => {
      const newSelected = new Map(checked);
      newSelected.set(id, !checked.get(id));

      setChecked(newSelected);
    },
    [checked],
  );

  const fetchMore = async () => {
    if (!loading && dialogs.length > 0 && !pagination.lastPage) {
      setLoading(true);
      try {
        const newDialogs = await ChatService.getConversations(pagination.page * 20);
        if (newDialogs.length > 0) {
          getChats([...dialogs, ...newDialogs]);
          setPagination({ page: pagination.page + 1, lastPage: false });
        } else {
          setPagination({ page: pagination.page, lastPage: true });
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  const onArchive = async () => {
    setLoading(true);
    const filtred = [...checked].filter(([_, value]) => value).map(([key]) => key);
    const archiveDialog = async (dialogId) => {
      try {
        const result = await ChatService.archiveDialog(dialogId);
        return result;
      } catch (e) {
        console.log(e);
        return e;
      }
    };
    await Promise.all(filtred.map(archiveDialog));
    navigation.setParams({ edit: false });
    setChecked(new Map());
    await onRefresh();
    setLoading(false);
  };

  const onUnArchive = async () => {
    setLoading(true);
    const filtred = [...checked].filter(([_, value]) => value).map(([key]) => key);
    const { items } = await ChatService.getArchivedList();
    const unArchiveDialog = async (dialogId) => {
      try {
        const result = await ChatService.unArchiveDialog(dialogId, items);
        return result;
      } catch (e) {
        console.log(e);
        return e;
      }
    };
    await Promise.all(filtred.map(unArchiveDialog));
    navigation.setParams({ edit: false });
    setChecked(new Map());
    await onRefresh();
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const filtred = [...checked].filter(([_, value]) => value).map(([key]) => key);
    const deleteDialog = async (dialogId) => {
      try {
        const result = await ChatService.deleteDialog(dialogId);
        return result;
      } catch (e) {
        console.log(e);
        return e;
      }
    };
    await Promise.all(filtred.map(deleteDialog));
    navigation.setParams({ edit: false });
    setChecked(new Map());
    await onRefresh();
    setLoading(false);
  };

  const onFilter = (list) => {
    const types = {
      archived: (item) => item.archived,
      read: (item) => !item.archived && item.unread_messages_count === 0,
      unread: (item) => !item.archived && item.unread_messages_count > 0,
      default: (item) => !item.archived,
      all: (item) => item,
    };

    const type = types[filter] || types.default;
    return list.filter(type);
  };

  const onSetFilter = (_, value) => {
    const types = {
      Todas: 'all',
      Padrão: 'default',
      Lidas: 'read',
      'Não Lidas': 'unread',
      Arquivadas: 'archived',
    };

    const type = types[value] || 'default';
    setFilter(type);
    setChecked(new Map());
    navigation.setParams({ edit: false });
    setLoading(false);
  };

  useEffect(() => {
    clearSelected();
    const unsubscribe = navigation.addListener('didFocus', () => {
      if (!isSelect) {
        clearSelected();
      }
    });

    return unsubscribe.remove;
  }, []);

  useEffect(() => {
    if (dialogs.length > 0 && isGuest) {
      onToChatPressed(dialogs[0])();
    }
    if (dialogs.length <= 20) {
      setPagination({ page: 1, lastPage: false });
    }
  }, [dialogs]);

  if (isGuest) {
    return null;
  }

  const setSearchTerm = async (value) => {
    ChatService.setSearchTerm(value);
    const result = await ChatService.getConversations();
    getChats(result);
  };

  return (
    <View style={{ flex: 1 }} testID="DialogScreen">
      <OfflineNotice network={isInternetReachable} />
      <DialogScreen
        theme={theme}
        dialogs={dialogs}
        toChat={onToChatPressed}
        avatar={avatar}
        checkTyping={checkTyping}
        typingText="Digitando..."
        user={user}
        typing={typing}
        refreshing={refreshing}
        onRefresh={onRefresh}
        noChatsText={noChatsText}
        edit={edit}
        checked={checked}
        onCheck={onCheck}
        onArchive={onArchive}
        onUnArchive={onUnArchive}
        onDelete={onDelete}
        loading={loading}
        filter={filter}
        onFilter={onFilter}
        setFilter={onSetFilter}
        fetchMore={fetchMore}
        setSearchTerm={setSearchTerm}
      />
    </View>
  );
};

DialogsScreen.defaultProps = {
  theme: undefined,
  avatar: null,
  dialogs: [],
  user: {},
  typing: {},
  isConnected: false,
  isInternetReachable: false,
  isGuest: false,
  getChats: () => {},
  selectDialog: () => {},
  navigation: {},
  extraData: null,
  clearSelected: () => {},
};

DialogsScreen.propTypes = {
  theme: PropTypes.shape(),
  avatar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  dialogs: PropTypes.arrayOf(PropTypes.shape()),
  user: PropTypes.shape(),
  typing: PropTypes.shape(),
  isConnected: PropTypes.bool,
  isInternetReachable: PropTypes.bool,
  getChats: PropTypes.func,
  selectDialog: PropTypes.func,
  navigation: PropTypes.shape(),
  extraData: PropTypes.shape(),
  clearSelected: PropTypes.func,
  noChatsText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

const mapStateToProps = (state) => ({
  dialogs: state.dialogs,
  user: state.user,
  typing: state.typing,
  isConnected: state.connection,
  isInternetReachable: state.internetReachable,
  extraData: state.extraData,
});

const mapDispatchToProps = (dispatch) => ({
  getChats: (dialogs) => dispatch(fetchDialogs(dialogs)),
  selectDialog: (dialog) => dispatch(setSelected(dialog)),
  clearSelected: () => dispatch(removeSelected()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogsScreen);
