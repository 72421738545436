import { StyleSheet } from 'react-native';

const colors = {
  PRIMARY: '#CF3D3A',
  TITLE: '#6F6F6F',
  SUB_TITLE: '#B2B2B2',
  TEXT: '#B2B2B2',
  TEXT_LIGHT: '#FFFFFF',
  TEXT_DARK: '#6F6F6F',
  BORDER_COLOR: '#F1F1F1',
  DEFAULT_BACKGROUND: '#FFFFFF',
  COMPONENT_BACKGROUND: '#FFFFFF',
  CONTRAST_BACKGROUND: '#FAFAFA',
  DARK_BACKGROUND: '#EEEEEE',
  ICON: '#6F6F6F',
  ICON_LIGHT: '#FFFFFF',
  GREEN: '#26AD4B',
  BLUE: '#5489E7',
  ERROR: '#CF3D3A',
};

export default StyleSheet.create({
  container: {
    backgroundColor: colors.COMPONENT_BACKGROUND,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: colors.BORDER_COLOR,
    padding: 0,
  },

  imageWrapper: {
    backgroundColor: colors.COMPONENT_BACKGROUND,
    borderRadius: 3,
    padding: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    aspectRatio: (140 / 140),
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },

  image: {
    width: '20vw',
    height: '30vh',
  },

  containerActive: {
    borderWidth: 1,
    borderColor: colors.GREEN,
    backgroundColor: '#EDEDED',
  },

  iconActive: {
    color: colors.TEXT_LIGHT,
    backgroundColor: colors.GREEN,
    position: 'absolute',
    top: 10,
    right: 10,
    width: 20,
    height: 20,
    borderRadius: 10,
    overflow: 'hidden',
    textAlign: 'center',
    lineHeight: 20,
  },
});
