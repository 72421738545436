import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import styles from './UnreadBadge.styles';

const UnreadBadge = ({ unreadMessagesCount }) => (
  unreadMessagesCount > 0 &&
  <View style={styles.container}>
    <Text style={styles.counter}>
      {unreadMessagesCount < 100 ? unreadMessagesCount : 99}
    </Text>
  </View>
);

UnreadBadge.defaultProps = {
  unreadMessagesCount: null,
};

UnreadBadge.propTypes = {
  unreadMessagesCount: PropTypes.number,
};

export default UnreadBadge;
