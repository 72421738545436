import React from 'react';
import SpecialMessageCard from './SpecialMessageCard';

export default (json) => {
  if (json.base === 'product') {
    const { data } = json;
    const { seller } = data;
    return <SpecialMessageCard product={data} seller={{ ...seller, image: seller.image.thumb }} />;
  }
  return null;
};
