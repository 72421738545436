import React from 'react';
import { View, Image, Text } from 'react-native';
import PropTypes from 'prop-types';
import { Title, P } from '../Typography';
import styles, { getImageWrapper } from './ProfileHeader.styles';

const ProfileHeader = ({
  name,
  image,
  polo,
  subpolo,
  large,
  minimumOrder,
}) => (
  <View style={styles.container}>
    <View style={getImageWrapper(large)}>
      {image && (
        <Image source={image} style={styles.image} />
      )}
    </View>

    <View style={styles.textContainer}>
      <Title bold color="#5C6879" large={large}>{name}</Title>
      <View style={styles.poloContainer}>
        {polo !== '' && <P color="#5C6879">{polo}</P>}
        {subpolo !== '' && <P color="#5C6879">{subpolo}</P>}
        {minimumOrder && (
          <P>
            <Text>Pedido mínimo nesta loja é de </Text>
            <Text>
              {minimumOrder}
              {minimumOrder === 1 ? ' peça' : ' peças'}
            </Text>
            <Text>. As peças podem ser variadas.</Text>
          </P>
        )}
      </View>
    </View>
  </View>
);

ProfileHeader.defaultProps = {
  polo: '',
  subpolo: '',
  large: false,
  image: null,
  minimumOrder: null,
};

ProfileHeader.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.shape({
    thumb: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
  }),
  polo: PropTypes.string,
  subpolo: PropTypes.string,
  large: PropTypes.bool,
  minimumOrder: PropTypes.number,
};

export default ProfileHeader;
