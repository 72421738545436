import isObject from 'isobject';
import {
  FETCH_DIALOGS,
  ADD_DIALOG,
  SORT_DIALOGS,
} from '../Actions/dialogs';
import { SELECT_DIALOG } from '../Actions/selected';
import { USER_LOGOUT } from '../Actions/user';

const isJson = (str) => {
  try {
    return [null, JSON.parse(str)];
  } catch (err) {
    return [err];
  }
};

const getSpecialBody = (json) => {
  if (json.items !== undefined) {
    return json.items.map(({ name }) => name).join(', ');
  }

  return 'Anexo';
};

const getBodyText = (body) => {
  const [err, json] = isJson(body);
  return (err === null && isObject(json))
    ? getSpecialBody(json)
    : body;
};

export default (dialogs = [], action) => {
  switch (action.type) {
    case FETCH_DIALOGS:
      return action.dialogs
        .sort((a, b) => new Date(b.last_message_date_sent) - new Date(a.last_message_date_sent));

    case ADD_DIALOG:
      return [action.dialog, ...dialogs];

    case SORT_DIALOGS: {
      const { message, count } = action;

      for (let i = 0; i < dialogs.length; i++) {
        const dialog = dialogs[i];

        if (dialog.id === message.dialog_id) {
          dialog.last_message = getBodyText(message.body);
          dialog.last_message_date_sent = message.date_sent;
          dialog.updated_at = message.date_sent;
          if (count) dialog.unread_messages_count += 1;
          dialogs.unshift(dialogs.splice(i, 1)[0]);
          break;
        }
      }

      return [...dialogs];
    }

    case SELECT_DIALOG: {
      for (let i = 0; i < dialogs.length; i++) {
        const dialog = dialogs[i];

        if (dialog.id === action.dialog.id) {
          dialog.unread_messages_count = 0;
          break;
        }
      }

      return [...dialogs];
    }

    case USER_LOGOUT:
      return [];

    default:
      return dialogs;
  }
};
