import { StyleSheet } from 'react-native';

const units = {
  DEFAULT_PADDING: 15,
  GUTTER: 7.5,
  TAB_ICON_SIZE: 14,
};

export const getImageWrapper = (large) => ({
  width: large ? 130 : 65,
  height: large ? 130 : 65,
  borderRadius: (large ? 130 : 65) / 2,
  overflow: 'hidden',
  marginRight: units.DEFAULT_PADDING,
});

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  polosContainer: {
    marginTop: 3,
  },

  image: {
    flex: 1,
    width: 80,
    height: 80,
  },

  textContainer: {
    paddingRight: units.DEFAULT_PADDING,
    width: 0,
    flexGrow: 1,
  },
});
