import {
  FETCH_MESSAGES,
  PUSH_MESSAGE,
  MARK_AS_SENT,
  MARK_AS_DELIVERED,
  MARK_AS_READ,
} from '../Actions/messages';
import { USER_LOGOUT } from '../Actions/user';

const uniq = (arr) => arr.filter((item, ix, base) => base.indexOf(item) === ix);

export default (history = [], action) => {
  switch (action.type) {
    case FETCH_MESSAGES: {
      return action.history;
    }

    case PUSH_MESSAGE: {
      const isNewMessage = history.filter(({ id }) => id === action.message.id).length === 0;
      return isNewMessage ? [action.message, ...history] : history;
    }

    case MARK_AS_SENT: {
      const { message } = action;
      const state = history.map((item) => {
        if (item.id === message.id) {
          return { ...item, sent: true };
        }
        return item;
      });
      return state;
    }

    case MARK_AS_DELIVERED: {
      const { payload } = action;
      const { messageId, userId } = payload;

      const state = history.map((item) => {
        if (item.id === messageId) {
          return { ...item, delivered: uniq([...(item.delivered || []), Number(userId)]) };
        }
        return item;
      });
      return state;
    }

    case MARK_AS_READ: {
      const { payload } = action;
      const { messageId, userId } = payload;

      const state = history.map((item) => {
        if (item.id === messageId) {
          return { ...item, read: uniq([...(item.read || []), Number(userId)]) };
        }
        return item;
      });
      return state;
    }

    case USER_LOGOUT:
      return [];

    default:
      return history;
  }
};
