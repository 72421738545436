import React from 'react';
import PropTypes from 'prop-types';
import { Image, View, Text } from 'react-native';
import { UnreadBadge } from '../../Atoms';
import { smallIcon, mediumIcon, largeIcon } from './ProfileIcon.styles';
import defaultTheme from '../../defaultTheme';

const ProfileIcon = ({
  theme,
  photo,
  name,
  iconSize,
  unreadCount,
  testID,
}) => {
  const styles = (iconSize === 'large'
    ? largeIcon(theme)
    : (iconSize === 'medium' ? mediumIcon(theme) : smallIcon(theme)));

  const randomizeColor = () => {
    const colors = [
      '#FF4848',
      '#FF68DD',
      '#9669FE',
      '#800080',
      '#9A03FE',
      '#3923D6',
      '#2966B8',
      '#23819C',
      '#5757FF',
      '#62A9FF',
      '#03EBA6',
      '#59955C',
      '#48FB0D',
      '#2DC800',
      '#9D9D00',
      '#B6BA18',
      '#FFB428',
      '#FF9331',
      '#F70000',
      '#B9264F',
      '#990099',
      '#74138C',
      '#0000CE',
      '#1F88A7',
      '#4A9586',
    ];

    return colors[name.length % colors.length];
  };

  const getIconLabel = () => {
    const words = name.split(' ');

    return (
      words.length > 1
        ? `${words[0].slice(0, 1)}${words[1].slice(0, 1)}`
        : name.slice(0, 2)
    );
  };

  return (
    <View testID={testID}>
      <UnreadBadge unreadMessagesCount={unreadCount} />
      {photo
        ? <Image style={styles.photo} source={photo} />
        : (
          <View style={[styles.photo, styles.randomPhoto, { backgroundColor: randomizeColor() }]}>
            <Text style={styles.randomIcon}>{getIconLabel()}</Text>
          </View>
        )}
    </View>
  );
};

ProfileIcon.defaultProps = {
  theme: defaultTheme,
  photo: null,
  name: '',
  iconSize: 'large',
  unreadCount: null,
};

ProfileIcon.propTypes = {
  theme: PropTypes.shape(),
  photo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  iconSize: PropTypes.string,
  unreadCount: PropTypes.number,
};

export default ProfileIcon;
