import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FF5568',
    position: 'absolute',
    zIndex: 1,
    right: 6,
    top: 4,
  },
  counter: {
    fontSize: 11,
    color: '#FFFFFF',
  }
});
