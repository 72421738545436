import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import styles from './CardImage.styles';

const CardImage = ({
  children,
  image,
  active,
  ...props
}) => (
  <TouchableOpacity
    style={[styles.container, active && styles.containerActive]}
    activeOpacity={0.8}
    delayPressIn={50}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <View style={styles.imageWrapper}>
      {image ? (
        <Image style={styles.image} source={image} resizeMode="cover" />
      ) : (
        null
      )}
    </View>

    {children}
  </TouchableOpacity>
);

CardImage.defaultProps = {
  active: false,
  image: undefined,
};

CardImage.propTypes = {
  image: PropTypes.shape(),
  children: PropTypes.element.isRequired,
  active: PropTypes.bool,
};

export default CardImage;
