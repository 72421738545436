import { Dialog, Message } from '@netlolo/core-models';
import ConnectyCube from './ConnectyCube';
import User from './UserService';
import CurrentUser from './CurrentUserDataService';
import Contacts from './ContactsDataService';
import saveFile from './saveFile';

const roomRequest = async (xmpp, skip = 0) => {
  const result = await xmpp.chat.dialog.list({ sort_desc: 'updated_at', skip });
  return result.items;
};

const getAllRooms = async (xmpp, total = 0, skip = 0, items = []) => {
  if (total < 100 || skip >= total) {
    return items;
  }
  const result = await roomRequest(xmpp, skip);
  return getAllRooms(xmpp, total, skip + 100, [...items, ...result]);
};

const getUserRole = (user, dialogId) => {
  if (user && user.custom_data && user.custom_data.dialogRoles) {
    return user.custom_data.dialogRoles.find((role) => role.dialogId === dialogId) || {};
  }

  return {};
};

const isZaxOnDialog = (roles, myId) => (
  roles
    .filter((item) => {
      return Number(item.userId) === Number(myId)
        && item.dialogRole === 'zaxpro';
    }).length > 0
);

const getDialogsName = (dialogs, myId) => (
  dialogs.map((dialog) => {
    const { occupants_ids: occupants, id: dialogId } = dialog;
    const botAndMe = [153393, 1589092, 402124, myId];
    const filtred = occupants.filter((id) => botAndMe.indexOf(id) === -1);

    if (dialog.description === 'zax'
      && dialog.data
      && dialog.data.roles
      && isZaxOnDialog(dialog.data.roles, myId)) {
      const [id] = filtred;
      const user = Contacts.get(id) || {};

      return {
        ...dialog,
        name: user.full_name,
      };
    }

    if (dialog.type === 3 || dialog.description === 'zax') {
      return dialog;
    }

    const users = filtred.map((id) => {
      const user = Contacts.get(id) || {};
      const { dialogRole } = getUserRole(user, dialogId);
      const [_, magentoId] = (user.email || '').split('@')[0].split('_');
      if (magentoId === dialog.description || dialogRole === 'subaccount') {
        return null;
      }
      return user.full_name;
    }).filter((name) => name !== null).join(', ');
    return {
      ...dialog,
      name: users.length !== 0
        ? users
        : dialog.name,
    };
  })
);

const fetchContactData = async (ids, skip = 0, index = 1, results = {}) => {
  const actual = ids.slice(skip, 100 * index);
  if (actual.length === 0) {
    return results;
  }

  const data = await User.listUsersByIds(actual);
  return fetchContactData(ids, skip + 100, index + 1, { ...results, ...data });
};

const getContactsData = async (ids) => {
  if (ids.length > 100) {
    return fetchContactData(ids);
  }

  return User.listUsersByIds(ids);
};

let searchTerm = '';

class ChatService {
  setSearchTerm(value) {
    searchTerm = value;
  }

  // Chat - Core
  async connect(user, dialogs) {
    if (!user) return null;

    try {
      await ConnectyCube.chat.connect({ userId: user.id, password: user.password });
      if (dialogs) {
        this.joinToAllConversations(dialogs);
      }
    } catch (e) {
      console.log('[ChatService] connect - ', e);
    }
    return null;
  }

  disconnect() {
    ConnectyCube.chat.disconnect();
  }

  // Chat - Dialogs
  async getConversations(skip = 0) {
    if (ConnectyCube.chat) {
      let result;
      if (searchTerm.length >= 3) {
        const searchUserResult = await ConnectyCube.users.get({
          full_name: searchTerm,
          per_page: 100,
        });
        const users = searchUserResult.items.map((item) => item.user.id);
        const searchResult = await ConnectyCube.chat.dialog.list({ sort_desc: 'last_message_date_sent', 'occupants_ids[in]': users });
        const withMe = searchResult.items.filter((item) => (
          item.occupants_ids.indexOf(ConnectyCube.service.getCurrentUserId()) !== -1
        ));
        result = { items: withMe };
      } else {
        result = await ConnectyCube.chat.dialog.list({ sort_desc: 'last_message_date_sent', limit: 20, skip });
      }

      const myId = ConnectyCube.service.getCurrentUserId();
      const { items } = result || {};
      const { items: archived } = await this.getArchivedList();
      const archivedList = archived.map((item) => item.dialogId);

      const dialogs = [];
      let contactsIds = [];

      for (let i = 0; i < items.length; i++) {
        if (items[i].type === 1) {
          continue;
        }
        const dialog = new Dialog(items[i], archivedList);

        if (dialog.type === 3) {
          dialog.destination = dialog.occupants_ids.find((occupant) => occupant !== myId);
        } else {
          dialog.destination = dialog.room_jid;
        }

        contactsIds = [...new Set(contactsIds.concat(dialog.occupants_ids))];
        dialogs.push(dialog);
      }

      if (contactsIds.length) {
        const contactsData = await getContactsData(contactsIds);
        await Contacts.set(contactsData);
        return getDialogsName(dialogs, myId);
      }

      return getDialogsName(dialogs, myId);
    }
    return [];
  }

  async createConversation(params) {
    const conversation = await ConnectyCube.chat.dialog.create(params);
    const dialog = new Dialog(conversation);

    if (dialog.type === 3) {
      dialog.destination = dialog.occupants_ids.find((occupant) => occupant !== CurrentUser.getProp('id'));
    } else {
      await ConnectyCube.chat.muc.join(dialog.room_jid);
      dialog.destination = dialog.room_jid;
    }

    return dialog;
  }

  joinToAllConversations(dialogs) {
    for (let dialog of dialogs) {
      if (dialog.type !== 3) {
        ConnectyCube.chat.muc.join(dialog.room_jid);
      }
    }
  }

  async getAttachments(attachment, setProgress, setDone) {
    if (attachment && !attachment.fileUrl) {
      const fileUrl = await saveFile(
        attachment.uid,
        ConnectyCube.storage.privateUrl(attachment.uid),
        setProgress,
        setDone,
      );
      return {
        ...attachment,
        fileUrl,
      };
    }
    return attachment;
  }

  // Chat - Messages
  async getHistory(dialogId, setProgress, setDone) {
    const result = await ConnectyCube.chat.message.list({
      chat_dialog_id: dialogId,
      sort_desc: 'date_sent',
    });
    const messages = result.items;
    const history = await Promise.all(messages.map(async (message) => {
      const item = new Message(message, Contacts);
      const attachment = await this.getAttachments(item.attachment, setProgress, setDone);
      item.setAttachment(attachment);
      return item;
    }));

    if (history.length > 0) {
      await this.readAllMessages(dialogId);
    }

    return history;
  }

  async readMessage(id, dialogId) {
    await ConnectyCube.chat.message.update(id, {
      chat_dialog_id: dialogId,
      read: 1,
    });
  }

  async readAllMessages(dialogId) {
    await ConnectyCube.chat.message.update(null, {
      chat_dialog_id: dialogId,
      read: 1,
    });
  }

  async deleteDialog(dialogId) {
    const result = await ConnectyCube.chat.dialog.delete(dialogId);
    return result;
  }

  async archiveDialog(dialogId) {
    const userId = ConnectyCube.service.getCurrentUserId();
    const data = { dialogId, userId };
    const result = await ConnectyCube.data.create('Archived', data);
    return result;
  }

  async unArchiveDialog(dialogId, items) {
    const { _id } = items.find((item) => item.dialogId === dialogId);
    const result = await ConnectyCube.data.delete('Archived', _id);
    return result;
  }

  async getArchivedList() {
    const userId = ConnectyCube.service.getCurrentUserId();
    const result = await ConnectyCube.data.list('Archived', { userId });
    return result;
  }
}

// create instance
const Chat = new ChatService();

// lock instance
Object.freeze(Chat);

export default Chat;
