import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppState, Platform } from 'react-native';
import { connect } from 'react-redux';
import debounce from 'debounce';
import { Actions } from '@netlolo/core-redux';
import { setMediaDone as setMediaDoneAction, setMediaProgress as setMediaProgressAction } from '@netlolo/core-redux/src/Actions/mediaProgress';
import Chat from '@netlolo/core-xmpp/src/services/ChatService';
import { ChatScreen as ChatTemplate } from '@netlolo/ui-base/src/Templates';
import ConnectyCube from '../../Helpers/ConnectyCube';
import sendMessage from './sendMessage';
import typeMessage from './typeMessage';
import getMessages from './getMessages';
import markAsRead from './markAsRead';
import { onSentStatusListener, onDeliveredStatusListener, onReadStatusListener } from './listeners';
import toChat from '../DialogsScreen/toChat';

const {
  fetchMessages: fetchMessagesAction,
  pushMessage: pushMessageAction,
  markAsSent: markAsSentAction,
  markAsDelivered: markAsDeliveredAction,
  markAsRead: markAsReadAction,
  sortDialogs: sortDialogsAction,
  setSelected: setSelectedAction,
  removeSelected: removeSelectedAction,
  setMessage: setMessageAction,
  setHistory: setHistoryAction,
} = Actions;

const ChatScreen = ({
  // Props
  theme,
  offset,
  externalRender,
  EmptyListMessage,
  AttachmentsButton,
  extraData,
  navigation,
  propDialog,
  mediaProgress,

  history,
  dialogsHistory,
  connected,
  systemMessage,
  user,
  setAsRead,
  setAsSent,
  setAsDelivered,
  setHistory,
  pushMessage,
  sortDialogs,
  setSystemMessage,
  fetchMessages,
  setSelected,
  removeSelected,
  setMediaProgress,
  setMediaDone,
}) => {
  const dialog = propDialog || navigation.getParam('dialog');
  if (!dialog || !user) {
    navigation.navigate('Main');
    return null;
  }
  if (dialog.lazyLoad) {
    Chat.getConversations().then((chatRooms) => {
      const loadedDialog = chatRooms.find(({ id }) => id === dialog.id);
      toChat({
        theme,
        selectDialog: setSelected,
        navigation,
        extraData,
      })(loadedDialog)();
    });
    return null;
  }
  const [inProgress, setProgress] = useState(false);
  const [appIsActive, setAppIsActive] = useState(true);
  const onSendMessage = sendMessage({
    dialog,
    user,
    connected,
    pushMessage,
    sortDialogs,
    setSystemMessage,
    xmpp: ConnectyCube,
    extraData,
    history,
    setHistory,
  });
  const onTypeMessage = typeMessage({
    dialog,
    connected,
    xmpp: ConnectyCube,
  });

  if (ConnectyCube.chat) {
    ConnectyCube.chat.onSentMessageCallback = onSentStatusListener(setAsSent);
    ConnectyCube.chat.onDeliveredStatusListener = onDeliveredStatusListener(setAsDelivered);
    ConnectyCube.chat.onReadStatusListener = onReadStatusListener(setAsRead);
  }

  useEffect(() => {
    // setDialog(propDialog);
    const oldMessages = dialogsHistory[dialog.id] || [];
    fetchMessages(oldMessages);

    if (Platform.OS === 'android' && oldMessages.length === 0) {
      setTimeout(() => {
        getMessages({
          dialog,
          user,
          fetchMessages,
          oldMessages,
          setProgress,
          setHistory,
          setMediaProgress,
          setMediaDone,
          xmpp: ConnectyCube,
        });
      }, 1000);
    } else {
      getMessages({
        dialog,
        user,
        fetchMessages,
        oldMessages,
        setProgress,
        setHistory,
        setMediaProgress,
        setMediaDone,
        xmpp: ConnectyCube,
      });
    }

    if (ConnectyCube.chat) {
      const { email } = user;
      const [_, baseId] = (email || '').split('_');
      const [realId] = (baseId || '').split('@');
      const sysProps = {
        body: `presence:${dialog.room_jid}:${user.id}:${dialog.description === realId}`,
        extension: {
          save_to_history: 1,
          dialog_id: dialog.id,
        },
      };

      const presenceInterval = setInterval(() => {
        const auxClient = ConnectyCube.chat.xmppClient;
        if (auxClient.status === 'online') {
          clearInterval(presenceInterval);
          try {
            ConnectyCube.chat.sendSystemMessage(153393, sysProps);
          } catch (e) { console.log(e); }

          try {
            ConnectyCube.chat.sendSystemMessage(1589092, sysProps);
          } catch (e) { console.log(e); }

          try {
            ConnectyCube.chat.sendSystemMessage(402124, sysProps);
          } catch (e) { console.log(e); }
        }
      }, 500);
    }

    AppState.addEventListener(
      'change',
      (nextAppState) => {
        if (nextAppState === 'active') {
          setAppIsActive(true);
          setSelected(dialog);
        } else {
          setAppIsActive(false);
        }
      },
    );
  }, [propDialog]);

  useEffect(() => {
    if (appIsActive) {
      const oldMessages = dialogsHistory[dialog.id] || [];
      fetchMessages(oldMessages);

      if (Platform.OS === 'android' && oldMessages.length === 0) {
        setTimeout(() => {
          getMessages({
            dialog,
            user,
            fetchMessages,
            oldMessages,
            setProgress,
            setHistory,
            setMediaProgress,
            setMediaDone,
            xmpp: ConnectyCube,
          });
        }, 1000);
      } else {
        getMessages({
          dialog,
          user,
          fetchMessages,
          oldMessages,
          setProgress,
          setHistory,
          setMediaProgress,
          setMediaDone,
          xmpp: ConnectyCube,
        });
      }
    }
  }, [appIsActive, propDialog]);

  useEffect(() => {
    setSelected(dialog);
    if (appIsActive) {
      debounce(() => {
        history.forEach(markAsRead({ user, xmpp: ConnectyCube }));
      }, 200);
    }
  }, [history, propDialog]);

  useEffect(() => () => {
    removeSelected();
    fetchMessages([]);
  }, [propDialog]);

  return (
    <ChatTemplate
      theme={theme}
      offset={offset}
      inProgress={inProgress}
      history={history}
      systemMessage={dialog.id === systemMessage.dialogId && systemMessage}
      userId={user.id}
      placeHolder="Digite sua mensagem..."
      onTypeMessage={onTypeMessage}
      sendMessage={onSendMessage}
      externalRender={externalRender}
      whats
      EmptyListMessage={EmptyListMessage}
      AttachmentsButton={AttachmentsButton}
      mediaProgress={mediaProgress}
      hideInput={dialog.type === 3 && dialog.occupants_ids.indexOf(2096959) !== -1}
    />
  );
};

ChatScreen.defaultProps = {
  theme: undefined,
  offset: 0,
  externalRender: () => {},
  EmptyListMessage: null,
  AttachmentsButton: null,
  extraData: null,
  navigation: {},
  history: [],
  connected: false,
  systemMessage: {},
  user: {},
  setAsRead: () => {},
  setAsSent: () => {},
  setAsDelivered: () => {},
  pushMessage: () => {},
  sortDialogs: () => {},
  setSystemMessage: () => {},
  fetchMessages: () => {},
  setSelected: () => {},
  removeSelected: () => {},
};

ChatScreen.propTypes = {
  theme: PropTypes.shape(),
  offset: PropTypes.number,
  externalRender: PropTypes.func,
  EmptyListMessage: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  AttachmentsButton: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  extraData: PropTypes.shape(),
  navigation: PropTypes.shape(),
  history: PropTypes.arrayOf(PropTypes.shape()),
  connected: PropTypes.bool,
  systemMessage: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      dialogId: PropTypes.string,
    }),
  ]),
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  setAsRead: PropTypes.func,
  setAsSent: PropTypes.func,
  setAsDelivered: PropTypes.func,
  pushMessage: PropTypes.func,
  sortDialogs: PropTypes.func,
  setSystemMessage: PropTypes.func,
  fetchMessages: PropTypes.func,
  setSelected: PropTypes.func,
  removeSelected: PropTypes.func,
};

const mapStateToProps = (state) => ({
  history: state.messages,
  dialogsHistory: state.history,
  user: state.user,
  connected: state.connection,
  systemMessage: state.systemMessages,
  mediaProgress: state.mediaProgress,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMessages: (history) => dispatch(fetchMessagesAction(history)),
  pushMessage: (message) => dispatch(pushMessageAction(message)),
  setAsSent: (message) => dispatch(markAsSentAction(message)),
  setAsDelivered: (payload) => dispatch(markAsDeliveredAction(payload)),
  setAsRead: (payload) => dispatch(markAsReadAction(payload)),
  sortDialogs: (message) => dispatch(sortDialogsAction(message)),
  setSelected: (dialog) => dispatch(setSelectedAction(dialog)),
  removeSelected: () => dispatch(removeSelectedAction()),
  setSystemMessage: () => dispatch(setMessageAction({})),
  setHistory: (dialogId, messages) => dispatch(setHistoryAction(dialogId, messages)),
  setMediaProgress: (uuid, progress) => dispatch(setMediaProgressAction(uuid, progress)),
  setMediaDone: (uuid) => dispatch(setMediaDoneAction(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatScreen);
