import React from 'react';
import { Bold } from '../../Atoms/Bold';
import { HyperLink } from '../../Atoms/HyperLink';

export const replaceTextWithHyperLink = (match, text = '') => {
  const urls = match || [];
  const links = urls.map((url) => (
    { url: url.trim(), component: () => <HyperLink url={url.trim()} /> }
  ));
  const result = [];
  let aux = text;

  links.forEach((link) => {
    const pieces = aux.split(link.url);
    const [before, ...rest] = pieces;

    if (before.trim().length > 0) {
      result.push(() => renderText(before));
    }

    result.push(link.component);
    aux = rest.join('');
  });
  if (result.length > 0) {
    result.push(() => renderText(aux));
    return result;
  }

  return text;
};

export const replaceTextWithBold = (match, text = '') => {
  const parts = match.map((item) => (
    {
      bold: item.match(/(\*+\1\+*)(.*?)\1+\+*/)[0],
      component: () => <Bold text={adapterMarkdown(item.match(/(\*+\1\+*)(.*?)\1+\+*/)[2])} />,
    }));
  const result = [];
  let aux = text;

  parts.forEach((link) => {
    const pieces = aux.split(link.bold);
    const [before, ...rest] = pieces;

    if (before.trim().length > 0) {
      result.push(() => adapterMarkdown(before));
    }

    result.push(link.component);
    aux = rest.join('');
  });
  if (result.length > 0) {
    result.push(() => adapterMarkdown(aux));
    return result;
  }

  return text;
};

const replaceTextWithParagraph = (match, text) => text.replace(/\\/g, '\n');
const rules = [
  {
    regex: /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi,
    type: 'HIPERLINK',
    func: ({ match, text }) => replaceTextWithHyperLink(match, text),
  },
  {
    regex: /(\*+\1\+*)(.*?)\1+\+*/gi,
    type: 'BOLD',
    func: ({ match, text }) => replaceTextWithBold(match, text),
  },
  {
    regex: /\\/g,
    type: 'PARAGRAPH',
    func: ({ match, text }) => replaceTextWithParagraph(match, text),
  },
];

const findMarkDown = (text) => rules
  .filter((item) => text.match(item.regex) !== null)
  .map((item) => ({ ...item, match: text.match(item.regex), rules }));

const executeMarkdown = (options, text = '') => options[0].func({
  ...options[0],
  text,
});

const render = (item) => item.map((subItem, ix) => {
  const AuxComponent = subItem;
  return AuxComponent && <AuxComponent key={ix.toString()} />;
});

export const adapterMarkdown = (text) => {
  const hasMark = findMarkDown(text) || [];
  if (hasMark.length > 0) {
    return executeMarkdown(hasMark, text);
  }
  return text;
};

export const renderText = (body = '') => {
  const aux = adapterMarkdown(body);
  if (typeof aux === 'string') return aux;
  return render(aux);
};

export default renderText;
