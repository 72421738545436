import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
  },

  largeTitle: {
    fontSize: 30,
  },

  paragraph: {
    fontSize: 14,
  },

  largeParagraph: {
    fontSize: 16,
  },

  smallParagraph: {
    fontSize: 10,
  },

  xsmallParagraph: {
    fontSize: 8,
  },

  bold: {
    fontWeight: 'bold',
  },
});

export const textBase = ({
  small,
  xsmall,
  bold,
  color,
  largeTitle,
  large,
}) => ([
  small && styles.smallParagraph,
  xsmall && styles.xsmallParagraph,
  bold && styles.bold,
  color && { color },
  largeTitle && styles.largeTitle,
  large && styles.largeParagraph,
]);

export default styles;
