import React from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';
import styles, { textBase } from './Typography.styles';

const Title = ({
  large,
  color,
  bold,
  style,
  ...props
}) => (
  <Text
    style={[
      styles.title,
      textBase({ largeTitle: large, color, bold }),
      style,
    ]}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

Title.defaultProps = {
  large: false,
  bold: false,
  color: '#646464',
  style: {},
};

Title.propTypes = {
  large: PropTypes.bool,
  bold: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.shape(),
};


export default Title;
