import React from 'react';
import PropTypes from 'prop-types';
import {
  KeyboardAvoidingView,
  Platform,
  View,
  Image,
  TouchableOpacity,
  Text,
} from 'react-native';
import { AuthForm } from '../../Atoms';
import baseStyle from './AuthScreen.styles';
import defaultTheme from '../../defaultTheme';
import logo from '../../../images/login-logo.png';

const AuthScreen = ({
  theme,
  signin,
  formTitle,
  loginPlaceholder,
  passwordPlaceholder,
  signinText,
  onPressCTA,
  ctaText,
}) => {
  const styles = baseStyle(theme);

  return (
    <View style={styles.wrapper}>
      <KeyboardAvoidingView
        style={styles.container}
        behavior={Platform.OS === 'ios' ? 'padding' : null}
        keyboardVerticalOffset={0}>
        <Image source={logo} style={styles.logo} />

        <AuthForm
          theme={theme}
          signin={signin}
          title={formTitle}
          loginPlaceholder={loginPlaceholder}
          passwordPlaceholder={passwordPlaceholder}
          signinText={signinText}
        />

        {ctaText.length > 0 && (
          <TouchableOpacity style={styles.signupTextContainer} onPress={onPressCTA}>
            <Text style={styles.signupText}>{ctaText}</Text>
          </TouchableOpacity>
        )}
      </KeyboardAvoidingView>
    </View>
  );
};

AuthScreen.defaultProps = {
  theme: defaultTheme,
  formTitle: '',
  onPressCTA: () => {},
  ctaText: '',
};

AuthScreen.propTypes = {
  theme: PropTypes.shape(),
  signin: PropTypes.func.isRequired,
  formTitle: PropTypes.string,
  loginPlaceholder: PropTypes.string.isRequired,
  passwordPlaceholder: PropTypes.string.isRequired,
  signinText: PropTypes.string.isRequired,
  onPressCTA: PropTypes.func,
  ctaText: PropTypes.string,
}

export default AuthScreen;
