import Chat from '@netlolo/core-xmpp/src/services/ChatService';
import User from '@netlolo/core-xmpp/src/services/UserService';
import markAsRead from './markAsRead';

const arrayToObject = (items) => items.reduce((obj, item) => ({
  ...obj,
  [item.id]: item,
}), {});

const mappedMessages = (oldMessages, messages) => {
  const messagesIds = messages.map(({ id }) => id);
  const notInHere = oldMessages.filter(({ id }) => messagesIds.indexOf(id) === -1);
  return [...messages, ...notInHere];
};

export default async ({
  dialog,
  user,
  fetchMessages,
  setProgress,
  oldMessages,
  setHistory,
  setMediaProgress,
  setMediaDone,
  xmpp,
}) => {
  try {
    await User.createSession(user);
    const messages = await Chat.getHistory(dialog.id, setMediaProgress, setMediaDone);
    const allMessages = mappedMessages(oldMessages, messages);
    setHistory(dialog.id, allMessages);
    const { history = [] } = fetchMessages(allMessages);

    history.forEach(markAsRead({ user, xmpp }));
  } catch (e) {
    console.warn('getMessages', e);
  }
  setProgress(false);
};
