import { useState } from 'react';
import { AppState } from 'react-native';
import debounce from 'debounce';
import NetInfo from '../NetInfo';
import xmppDisconnect from './xmppDisconnect';
import xmppConnect from './xmppConnect';

const tryToConnect = debounce(async (reconnectListener) => {
  if (reconnectListener) {
    await reconnectListener();
  }
}, 600);

let keepAliveUnsubscriber = null;

export default ({
  // Props
  isConnected,
  hasInternet,
  user,
  dialogsHistory,

  // Actions
  setConnected,
  setDisconnected,
  setConnecting,
  setLoggedOut,
  setReachable,
  setUnreachable,
  getChats,
  sortChats,

  // Resources
  xmpp,
}) => {
  const [waitConnect, setWaitConnect] = useState(false);
  const disconnectListener = xmppDisconnect({ setDisconnected, setWaitConnect });
  const reconnectListener = xmppConnect({
    isConnected,
    user,
    waitConnect,
    dialogsHistory,

    setConnected,
    setConnecting,
    setLoggedOut,
    setDisconnected: disconnectListener,
    getChats,
    sortChats,
    setWaitConnect,

    xmpp,
  });
  const markAsDisconnected = () => {
    setDisconnected();
  };

  const netInfoUnsubscriber = NetInfo.addEventListener((state) => {
    if (state.isConnected && state.isInternetReachable) {
      setReachable();
    } else {
      setUnreachable();
    }
  });

  AppState.addEventListener('change', async (nextAppState) => {
    if (nextAppState === 'active' && hasInternet && tryToConnect) {
      await tryToConnect(reconnectListener);
    }
  });

  const keepAliveListener = (aux) => {
    const keepAlive = setInterval(async (service) => {
      if (service.chat.xmppClient.connected) {
        service.chat.markActive();
      } else if (tryToConnect) {
        await tryToConnect(reconnectListener);
      }
    }, 60 * 1000, aux);
    return () => {
      keepAliveUnsubscriber = null;
      clearInterval(keepAlive);
    };
  };

  if (!keepAliveUnsubscriber) {
    keepAliveUnsubscriber = keepAliveListener(xmpp);
  }

  return {
    // Actions
    tryToConnect: () => tryToConnect(reconnectListener),
    markAsDisconnected,
    // Listeners
    reconnectListener,
    disconnectListener,
    // Unsubscribers
    netInfoUnsubscriber,
    keepAliveUnsubscriber,
  };
};
