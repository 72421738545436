import { Platform } from 'react-native';
import { Message, SystemMessage } from '@netlolo/core-models';
import Chat from '@netlolo/core-xmpp/src/services/ChatService';
import Contacts from '@netlolo/core-xmpp/src/services/ContactsDataService';
import ConnectyCube from '../../Helpers/ConnectyCube';

export const systemMessageListener = ({ setSystemMessage, getChats }) => async (message) => {
  if (message.body.startsWith('room:')) {
    // eslint-disable-next-line no-unused-vars
    const [_, roomJID] = message.body.split(':');
    await ConnectyCube.chat.muc.join(roomJID);
    const dialogs = await Chat.getConversations();
    getChats(dialogs);
  } else {
    setSystemMessage(new SystemMessage(message));
  }
};

const mapSender = (sender = {}) => ({
  ...sender,
  full_name: (sender.full_name || '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
});

export const messageListener = ({
  user,
  selected,
  addToHistory,
  sortChats,
  appIsActive,
  push,
  setMediaProgress,
  setMediaDone,
}) => async (id, msg) => {
  const dateSent = Number(msg.date_sent || (msg.extension && msg.extension.date_sent)) * 1000;
  const message = new Message({ ...msg, date_sent: dateSent }, Contacts);
  const attachment = await Chat.getAttachments(message.attachment, setMediaProgress, setMediaDone);
  message.setAttachment(attachment);

  if (id !== user.id) {
    if (selected.id === message.dialog_id) {
      addToHistory(message);
      sortChats(message);
      if (appIsActive) {
        Chat.readMessage(message.id, message.dialog_id);
      } else {
        push.createLocalNotification(
          message.body,
          {
            dialogId: message.dialog_id,
            sender: mapSender(message.sender),
          },
        );
      }
    } else {
      push.createLocalNotification(
        message.body,
        {
          dialogId: message.dialog_id,
          sender: mapSender(message.sender),
        },
      );
      sortChats(message, true);
    }
  }
};

const getDialog = (dialogs, dialogId) => {
  const dialog = dialogs.find(({ id }) => id === dialogId);
  if (dialog) {
    return dialog;
  }

  return { id: dialogId, lazyLoad: true };
};

export const notificationListener = ({
  dialogs,
  navigation,
  theme,
  extraData,
}) => async (rawNotification) => {
  const { data, userInteraction } = rawNotification || {};
  const notification = {
    ...rawNotification,
    userInteraction: (data || {}).openedInForeground
      ? true
      : userInteraction,
  };
  if ((notification && notification.userInteraction) && (
    notification.data
    // eslint-disable-next-line no-underscore-dangle
    || notification._data
    || notification.dialog_id
    || notification.content
  )) {
    const {
      dialog_id: diagId,
      content,
    // eslint-disable-next-line no-underscore-dangle
    } = notification.data || notification._data || notification;
    const { dialogId } = Platform.OS === 'android'
      ? JSON.parse((content || '{}').replace(/=>/g, ':').replace(/nil/g, 'null'))
      : (content || {});
    const dialog = getDialog(dialogs, dialogId || diagId);

    await navigation.navigate('Chat', {
      extraData: { ...extraData },
      dialog: { ...dialog },
      theme,
    });
  }
};

export const onTyping = (typing) => (
  isTyping,
  userId,
  dialogId,
) => typing({ isTyping, userId, dialogId });

export default ({
  onMessageListener,
  onSystemMessageListener,
  onTypingMessage,
}) => {
  ConnectyCube.chat.onMessageListener = onMessageListener;
  ConnectyCube.chat.onSystemMessageListener = onSystemMessageListener;
  ConnectyCube.chat.onMessageTypingListener = onTypingMessage;
};
