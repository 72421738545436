import { Contacts } from '@netlolo/core-xmpp/src/services';
import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import {
  fetchDialogs,
  userLogin,
  fetchMessages,
  pushMessage,
  markAsDelivered,
  markAsRead,
  setSelected,
} from '@netlolo/core-redux/src/Actions';
import { Dialog, Message } from '@netlolo/core-models';

const WhatsAppService = createContext(null);

export { WhatsAppService };

let socket;

const random = () => Math.floor(Math.random() * 1000) + 1;

const WhatsAppServiceProvider = ({ children }) => {
  if (!socket) {
    socket = io.connect('http://34.122.110.222:9597');
  }
  const dispatch = useDispatch();

  const init = (uuid) => {
    socket.emit('create', uuid);
  };

  const login = () => {
    socket.emit('login');
  };

  const getConversations = () => {
    socket.emit('getDialogs');
  };

  const getDialogHistory = (chatId) => {
    socket.emit('getDialogHistory', { chatId });
  };

  const sendMessage = (to, body) => {
    socket.emit('sendMessage', { to, body });
    getDialogHistory(to);
  };

  const sendTyping = (to) => {
    socket.emit('typing', { to, status: true });
  };

  const stopTyping = (to) => {
    socket.emit('typing', { to, status: false });
  };

  const createDialog = () => {
    socket.emit('createDialog', { participants: ['5511949825976@c.us'], name: `[Natura] Visitante ${random()}` });
  };

  socket.on('login:result', (data) => {
    dispatch(userLogin(data));
  });

  socket.on('message', (data) => {
    const message = new Message({
      id: data.id,
      body: data.body,
      dialog_id: data.to,
      date_sent: data.timestamp * 1000,
      sender_id: data.from,
      senderWA: data.sender,
      delivered_ids: data.ack >= 2 ? [true] : [],
      read_ids: data.ack === 3 ? [true] : [],
    }, Contacts);
    dispatch(pushMessage(message));
    socket.emit('setRead', { chatId: data.chatId });
  });

  socket.on('message:delivered', (messageId) => {
    dispatch(markAsDelivered({ messageId, userId: 1 }));
  });

  socket.on('message:read', (messageId) => {
    dispatch(markAsRead({ messageId, userId: 1 }));
  });

  socket.on('getDialogHistory:result', (data) => {
    const messages = data.messages.map((item) => {
      const message = new Message({
        id: item.id,
        body: item.body,
        dialog_id: item.to,
        date_sent: item.timestamp * 1000,
        sender_id: item.from,
        senderWA: item.sender,
        delivered_ids: item.ack >= 2 ? [true] : [],
        read_ids: item.ack === 3 ? [true] : [],
      }, Contacts);
      return message;
    }).filter((item) => !!item.body).reverse();
    dispatch(fetchMessages(messages));
  });

  socket.on('getDialogs:result', (data) => {
    const dialogs = data.map((item) => {
      const dialog = new Dialog({
        id: item.id,
        type: 2,
        room_jid: item.id,
        name: item.name,
        photo: null,
        description: '',
        destination: item.id,
        user_id: null,
        admins_ids: item.groupMetadata.participants.filter((participant) => participant.isAdmin).map((participant) => participant.id._serialized),
        occupants_ids: item.groupMetadata.participants.map((participant) => participant.id._serialized),
        updated_date: item.t,
        last_message: null,
        last_message_id: null,
        last_message_user_id: null,
        unread_messages_count: 0,
        unread_messages_ids: null,
        pinned_messages_ids: null,
        data: null,
      });
      return dialog;
    });
    dispatch(fetchDialogs(dialogs));
  });

  socket.on('createDialog:result', (data) => {
    const dialog = new Dialog(data);
    dispatch(setSelected(dialog));
  });

  const value = {
    socket,
    init,
    login,
    getConversations,
    getDialogHistory,
    sendMessage,
    sendTyping,
    stopTyping,
    createDialog,
  };

  return (
    <WhatsAppService.Provider value={value}>
      {children}
    </WhatsAppService.Provider>
  );
};

WhatsAppServiceProvider.defaultProps = {
  children: null,
};

WhatsAppServiceProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default WhatsAppServiceProvider;
