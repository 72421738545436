// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  chatConnected,
  chatDisconnected,
  chatConnecting,
  userLogout,
  internetReachable as internetReachableAction,
  internetUnreachable,
  fetchDialogs,
  sortDialogs as sortDialogsAction,
} from '@netlolo/core-redux/src/Actions';
import ConnectionHooks from './Connection.hooks';

const Connection = ({
  // Props
  xmpp,
  children,

  // State
  isConnected,
  hasInternet,
  user,
  dialogsHistory,

  // Actions
  setConnected,
  setDisconnected,
  setConnecting,
  setLoggedOut,
  setReachable,
  setUnreachable,
  getChats,
  sortChats,
}) => {
  const connectionSetup = {
    // Props
    isConnected,
    hasInternet,
    user,
    dialogsHistory,

    // Actions
    setConnected,
    setDisconnected,
    setConnecting,
    setLoggedOut,
    setReachable,
    setUnreachable,
    getChats,
    sortChats,

    // Resources
    xmpp,
  };
  const {
    // Actions
    tryToConnect,
    markAsDisconnected,
    // Unsubscribers
    netInfoUnsubscriber,
    keepAliveUnsubscriber,
  } = ConnectionHooks(connectionSetup);

  useEffect(() => {
    (async () => {
      if (tryToConnect) {
        await tryToConnect();
      }
    })();
  }, []);

  useEffect(() => {
    if (hasInternet) {
      (async () => {
        if (tryToConnect) {
          await tryToConnect();
        }
      })();
    } else {
      markAsDisconnected();
    }

    return () => {
      netInfoUnsubscriber();
      keepAliveUnsubscriber();
    };
  }, [hasInternet]);

  return children;
};

const mapStateToProps = (state) => ({
  isConnected: state.connection,
  hasInternet: state.internetReachable,
  user: state.user,
  dialogsHistory: state.history,
});

const mapDispatchToProps = (dispatch) => ({
  setConnected: () => dispatch(chatConnected()),
  setDisconnected: () => dispatch(chatDisconnected()),
  setConnecting: () => dispatch(chatConnecting()),
  setLoggedOut: () => dispatch(userLogout()),
  setReachable: () => dispatch(internetReachableAction()),
  setUnreachable: () => dispatch(internetUnreachable()),
  getChats: (dialogs) => dispatch(fetchDialogs(dialogs)),
  sortChats: (message) => dispatch(sortDialogsAction(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Connection);
