const defaultMessage = {
  id: '',
  body: '',
  dialog_id: '',
  date_sent: Number(Date.now()),
  attachments: null,
  sender_id: null,
  sender: null,
  sent: null,
  delivered: null,
  read: null,
  isList: false,
  data: null,
  custom_attachment: null,
};

const getAttachments = (msg) => {
  const { extension, attachments: rootAttachments = [], attachment } = msg;
  const { attachments: extAttachments = [] } = extension || {};
  return [...extAttachments, ...rootAttachments, attachment]
    .filter((data) => data !== undefined);
};

const getFileAttachments = (msg) => {
  const attachments = getAttachments(msg);
  return attachments.find(({ type }) => type !== 'data');
};

const getDataAttachments = (msg) => {
  const attachments = getAttachments(msg);
  const toQuotes = (str) => str.replace(/&quot;/g, '"');
  const { value } = attachments.find(({ type }) => type === 'data') || {};
  return value ? JSON.parse(toQuotes(value)) : null;
};

const getCustomAttachments = (msg) => {
  const attachments = getAttachments(msg);
  const toQuotes = (str) => str.replace(/&quot;/g, '"');
  const { value } = attachments.find(({ type }) => type === 'custom') || {};
  return value ? JSON.parse(toQuotes(value)) : null;
};

const getActionAttachments = (msg) => {
  const attachments = getAttachments(msg);
  const { value } = attachments.find(({ type }) => type === 'action') || {};
  return value;
};

const getDate = (timestamp) => {
  if (!timestamp) {
    return Number(Date.now());
  }
  const { length } = timestamp.toString();
  const hasMili = length >= 13;
  const realTimestamp = hasMili ? Number(timestamp) : Number(timestamp) * 1000;
  return Number(new Date(realTimestamp));
};

export default class Message {
  constructor(msg = defaultMessage, Contacts = {}) {
    this.id = msg.id || msg._id;
    this.body = msg.body || msg.message;
    this.dialog_id = msg.chat_dialog_id || (msg.extension && msg.extension.dialog_id);
    this.date_sent = getDate(msg.date_sent || (msg.extension && msg.extension.date_sent));
    this.attachment = getFileAttachments(msg);
    this.sender_id = msg.sender_id || (msg.extension && msg.extension.sender_id);
    this.sender = this.getSender(msg, Contacts);
    this.sent = !!msg.date_sent;
    this.delivered = msg.delivered_ids;
    this.read = msg.read_ids;
    this.data = getDataAttachments(msg);
    this.custom_attachment = getCustomAttachments(msg);
    this.action = getActionAttachments(msg);
  }

  getSender(msg = null, Contacts = {}) {
    if (msg && msg.senderWA) {
      return { full_name: msg.senderWA.formattedName };
    }
    const id = msg.sender_id || (msg.extension && msg.extension.sender_id);
    return id && Contacts.get(id);
  }

  setAttachment = (attachment) => {
    this.attachment = attachment;
  }
}
