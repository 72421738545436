import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    backgroundColor: '#fff3cd',
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    color: '#856404'
  },
  error: {
    backgroundColor: '#f8d7da',
  },
  textError: {
    color: '#721c24'
  },
});
