import { Message as MessageModel } from '@netlolo/core-models';
import Contacts from '@netlolo/core-xmpp/src/services/ContactsDataService';
import ConnectyCube from '../../Helpers/ConnectyCube';

const trimMessage = (message) => (
  message
    .replace(/^\s+|\s+$/g, '')
    .trim()
);

export default ({
  messageValue,
  dialog,
  user,
  pushMessage,
  sortDialogs,
  setSystemMessage,
  setMessageValue,
  xmpp,
  history,
  setHistory,
  extraData = null,
}) => (textMessage = null, auxSetMessage = null, attachments = null) => {
  try {
    const body = typeof textMessage === 'string' ? trimMessage(textMessage) : trimMessage(messageValue);
    // const date = Number(Date.now());

    if (!body) {
      return;
    }

    const msg = {
      type: dialog.xmpp_type,
      body,
      extension: {
        save_to_history: 1,
        dialog_id: dialog.id,
        sender_id: user.id,
        // Commented out to try to fix a problem with the updated_at on dialog
        // date_sent: date,
      },
      markable: 1,
    };

    if (extraData) {
      msg.extension.attachments = [
        {
          value: JSON.stringify({ ...extraData }),
          type: 'data',
        },
      ];
    }

    if (attachments) {
      msg.extension.attachments = [
        ...(msg.extension.attachments || []),
        attachments,
      ];
    }

    if ((msg.body || '').toLowerCase() === '/finalizar') {
      msg.body = 'Atendimento encerrado.';
      msg.extension.attachments = [
        ...(msg.extension.attachments || []),
        {
          value: 'closeSupport',
          type: 'action',
        },
      ];
    }

    const systemMessage = {
      body: `room:${dialog.room_jid}`,
      extension: {
        save_to_history: 1,
        dialog_id: dialog.id,
      },
    };

    try {
      xmpp.chat.sendSystemMessage(153393, systemMessage);
    } catch (e) { console.log(e); }

    try {
      xmpp.chat.sendSystemMessage(1589092, systemMessage);
    } catch (e) { console.log(e); }

    try {
      xmpp.chat.sendSystemMessage(402124, systemMessage);
    } catch (e) { console.log(e); }

    msg.id = xmpp.chat.send(dialog.destination, msg);

    const message = new MessageModel(msg, Contacts);
    if (attachments && attachments.type && attachments.type === 'photo') {
      message.setAttachment({
        ...attachments,
        fileUrl: ConnectyCube.storage.privateUrl(attachments.uid),
      });
    }

    pushMessage(message);
    sortDialogs(message);
    setSystemMessage({});
    if (auxSetMessage) {
      auxSetMessage('');
    } else {
      try {
        setMessageValue('');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    setHistory(dialog.id, [...history, message]);
  } catch (e) {
    console.log('sendMessage', e);
  }
};
