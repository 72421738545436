import { CHAT_CONNECTED, CHAT_CONNECTING, CHAT_DISCONNECTED } from '../Actions/connection';

export default (connecting = false, action) => {
  switch (action.type) {
    case CHAT_CONNECTED:
    case CHAT_DISCONNECTED:
      return false;
    case CHAT_CONNECTING:
      return true;
    default:
      return connecting;
  }
};
