import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import baseStyle from './MessageButton.styles';
import defaultTheme from '../../defaultTheme';

const isBold = text => text.startsWith('*') && text.endsWith('*');

const MessageButton = ({ onPress, text, theme }) => {
  const styles = baseStyle(theme);
  const bold = isBold(text);
  const btnText = bold ? text.substring(1, text.length - 1) : text;

  return (
    <TouchableOpacity onPress={onPress} style={[styles.wrapper, bold && styles.bold]}>
      <View>
        <Text style={[styles.text, bold && styles.bold]}>{btnText}</Text>
      </View>
    </TouchableOpacity>
  );
};

MessageButton.defaultProps = {
  theme: defaultTheme,
};

export default MessageButton;
