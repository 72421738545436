import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  time: {
    height: 15,
    marginTop: 3,
    fontSize: 10,
    fontWeight: '300'
  }
});
