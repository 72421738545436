import { StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  loginContainer: {
    width: '100%',
  },
  welcome: {
    fontSize: 24,
    textAlign: 'center',
    paddingTop: 30,
  },
  inputContainer: {
    paddingVertical: 20,
    width: '100%',
  },
  input: {
    borderBottomWidth: 1,
    borderColor: theme.colors.TEXT,
    padding: 10,
  },
  button: {
    backgroundColor: theme.colors.PRIMARY,
    alignSelf: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 20,
    borderRadius: 8,
  },
  buttonText: {
    color: theme.colors.LIGHT_BG,
    fontSize: 16,
  },
  buttonDisabled: {
    backgroundColor: theme.colors.DISABLED,
  },
});
