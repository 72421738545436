import Chat from '@netlolo/core-xmpp/src/services/ChatService';

export default ({
  isConnected,
  setRefreshing,
  getChats,
  setPagination,
}) => async () => {
  if (!isConnected) {
    return;
  }
  setRefreshing(true);

  try {
    const dialogs = await Chat.getConversations();
    setPagination({ page: 1, lastPage: false });
    getChats(dialogs);
  } catch (e) {
    console.log('onRefresh', e);
  }
  setRefreshing(false);
};
