import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { connect } from 'react-redux';

const styles = StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    borderRadius: 20,
    backgroundColor: '#FF5568',
    alignItems: 'center',
    justifyContent: 'center',
  },

  text: {
    fontSize: 11,
    color: '#FFFFFF',
  },
});

const UnreadBadge = ({ dialogs }) => {
  const unread = dialogs.reduce((total, item) => total + item.unread_messages_count, 0);

  return (
    unread > 0 && (
      <View style={styles.container}>
        <Text style={styles.text} testID="badgeText">{unread}</Text>
      </View>
    )
  );
};

const mapStateToProps = (state) => ({
  dialogs: state.dialogs,
});

export default connect(mapStateToProps)(UnreadBadge);
