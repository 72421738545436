import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import {
  View,
  Text,
  Image,
  Dimensions,
  Platform,
} from 'react-native';
import isObject from 'isobject';
import { Check, MessageButton } from '../../Atoms';
import renderText from '../Markdown/adapterMarkdown';
import baseStyles from './Message.styles';
import defaulTheme from '../../defaultTheme';

const Message = ({
  theme,
  message,
  otherSender,
  delivered,
  read,
  sent,
  sender,
  showSenderName,
  onPress,
  whatsAppStyle,
  isLast,
  externalRender,
  realWidth,
  actualProgress,
}) => {
  const fullWidth = Dimensions.get('window').width;
  const styles = baseStyles(theme, whatsAppStyle, Math.min(realWidth, fullWidth));

  const getTime = (dateSent) => {
    const date = dateSent ? new Date(dateSent) : new Date();
    const padZeros = (val) => val.toString().padStart(2, '0');
    const time = whatsAppStyle
      ? `${padZeros(date.getHours())}:${padZeros(date.getMinutes())}`
      : moment(date).calendar();
    return (
      <Text style={styles.dateSent}>{time}</Text>
    );
  };

  const isNumber = (str) => {
    const number = Number(str);
    return typeof number === 'number' && !Number.isNaN(number);
  };

  const isJson = (str) => {
    try {
      if (isNumber(str)) {
        return ['only number message'];
      }

      return [null, JSON.parse(str)];
    } catch (err) {
      return [err];
    }
  };

  const renderList = (items) => (
    items.map((item) => (
      <MessageButton
        key={item.name}
        theme={theme}
        onPress={() => onPress(item.action)}
        text={item.name}
      />
    ))
  );

  const renderBody = (body, attachment, customAttachment, render) => {
    const checkBody = isJson(body);
    const [err, json] = checkBody;
    const isList = err === null && isObject(json) && json.base === 'list';

    if (err !== null && !isList) {
      if (customAttachment) {
        return (
          <View style={styles.customMessage}>
            {render(customAttachment)}
          </View>
        );
      }

      if (attachment && !message.action) {
        if (actualProgress < 1) {
          return (
            <Text
              style={[
                styles.messageText,
                (otherSender ? styles.selfToLeft : styles.selfToRight),
                (whatsAppStyle && styles.messageTextWhatsAppStyle),
              ]}
            >
              Carregando imagem...
            </Text>
          );
        }

        let uri = attachment.fileUrl || '';
        if (Platform.OS === 'android') {
          uri = uri.startsWith('file://')
            ? uri
            : `file://${uri}`;
        }

        return (
          <Image
            source={{ uri }}
            style={{ width: 250, height: 350 }}
          />
        );
      }

      return (
        <Text
          style={[
            styles.messageText,
            (otherSender ? styles.selfToLeft : styles.selfToRight),
            (whatsAppStyle && styles.messageTextWhatsAppStyle),
          ]}
        >
          {renderText(body)}
        </Text>
      );
    }

    if (err === null && !isList) {
      return (
        <View style={styles.customMessage}>
          {render(json)}
        </View>
      );
    }

    return (
      <View style={[whatsAppStyle ? styles.list : {}]}>
        {renderList(json.items)}
      </View>
    );
  };

  const getNameValue = (name) => name.split('').reduce((total, char) => total + char.charCodeAt(0), 0);

  const getColor = (name = '') => {
    const colors = [
      '#FF4848',
      '#FF68DD',
      '#9669FE',
      '#800080',
      '#9A03FE',
      '#3923D6',
      '#2966B8',
      '#23819C',
      '#5757FF',
      '#62A9FF',
      '#03EBA6',
      '#59955C',
      '#48FB0D',
      '#2DC800',
      '#9D9D00',
      '#B6BA18',
      '#FFB428',
      '#FF9331',
      '#F70000',
      '#B9264F',
      '#990099',
      '#74138C',
      '#0000CE',
      '#1F88A7',
      '#4A9586',
    ];
    const nameValue = getNameValue(name);

    return colors[nameValue % colors.length];
  };

  return (
    <View
      style={[
        styles.container,
        (otherSender ? styles.positionToLeft : styles.positionToRight),
        (whatsAppStyle && isLast) && styles.last,
      ]}
    >
      <View
        style={[
          styles.message,
          (otherSender ? styles.messageToLeft : styles.messageToRight),
          (whatsAppStyle && styles.messageWhatsAppStyle),
        ]}
      >
        <View
          style={[
            { flexDirection: 'column', minHeight: showSenderName ? 34 : 0 }
          ]}>
          {showSenderName && (
            <Text style={{ fontWeight: 'bold', color: getColor(sender) }}>{sender}</Text>
          )}
          {renderBody(message.body, message.attachment, message.custom_attachment, externalRender)}
        </View>
        <View
          style={[
            styles.status,
            (!whatsAppStyle && !otherSender) && { justifyContent: 'flex-end' },
            (whatsAppStyle && styles.statusWhatsAppStyle),
          ]}
        >
          {getTime(message.date_sent)}
          <View style={{ paddingLeft: whatsAppStyle ? 0 : 5 }}>
            <Check sent={sent} delivered={delivered} read={read} mine={!otherSender} />
          </View>
        </View>
      </View>
    </View>
  );
};

Message.defaultProps = {
  theme: defaulTheme,
  delivered: false,
  read: false,
  sent: false,
  showSenderName: false,
  onPress: () => {},
  isLast: false,
  whatsAppStyle: false,
  externalRender: () => {},
  realWidth: null,
};

Message.propTypes = {
  theme: PropTypes.shape(),
  message: PropTypes.shape().isRequired,
  otherSender: PropTypes.bool.isRequired,
  delivered: PropTypes.bool,
  read: PropTypes.bool,
  sent: PropTypes.bool,
  sender: PropTypes.string.isRequired,
  showSenderName: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  onPress: PropTypes.func,
  isLast: PropTypes.bool,
  whatsAppStyle: PropTypes.bool,
  externalRender: PropTypes.func,
  realWidth: PropTypes.number,
};

export default Message;
