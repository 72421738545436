import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  Dimensions,
} from 'react-native';
import { MessageList } from '../../Organisms';
import { SystemMessageList } from '../../Molecules';
import { MessageInput } from '../../Atoms';
import baseStyle from './ChatScreen.styles';
import defaultTheme from '../../defaultTheme';

const window = Dimensions.get('window');
const screen = Dimensions.get('screen');

const ChatScreen = ({
  theme,
  offset,
  inProgress,
  history,
  systemMessage,
  userId,
  placeHolder,
  onTypeMessage,
  sendMessage,
  externalRender,
  whats,
  EmptyListMessage,
  AttachmentsButton,
  mediaProgress,
  boticario,
  hideInput,
}) => {
  const [dimensions, setDimensions] = useState({ window, screen });
  const { height } = dimensions.window;
  const styles = baseStyle(theme, height - (boticario ? 120 : 50), boticario);

  const onChange = (prop) => {
    setDimensions({ window: prop.window, screen: prop.screen });
  };

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={Platform.OS === 'ios' ? -222 : offset}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      style={styles.wrapper}
      testID="ChatScreen"
    >

      {inProgress && (
        <ActivityIndicator
          style={styles.activityIndicator}
          size={theme.mixins.loadingSize}
          color={theme.colors.TEXT}
        />
      )}

      {EmptyListMessage && history.length === 0 && (
        <EmptyListMessage />
      )}

      <MessageList
        theme={theme}
        history={history}
        userId={userId}
        whats={whats}
        onPress={(action) => sendMessage(action)}
        externalRender={externalRender}
        mediaProgress={mediaProgress}
      />

      {(systemMessage && systemMessage.data) && (
        <SystemMessageList
          items={systemMessage.data.items || []}
          onPress={(action) => sendMessage(action)}
        />
      )}

      {!hideInput && (
        <MessageInput
          theme={theme}
          placeholder={placeHolder}
          onTypeMessage={onTypeMessage}
          sendMessage={sendMessage}
          AttachmentsButton={AttachmentsButton}
        />
      )}
    </KeyboardAvoidingView>
  );
};

ChatScreen.defaultProps = {
  theme: defaultTheme,
  offset: 0,
  inProgress: false,
  history: [],
  systemMessage: null,
  externalRender: () => {},
  whats: false,
  EmptyListMessage: null,
  AttachmentsButton: null,
  mediaProgress: {},
  boticario: false,
  hideInput: false,
};

ChatScreen.propTypes = {
  theme: PropTypes.shape(),
  offset: PropTypes.number,
  inProgress: PropTypes.bool,
  history: PropTypes.arrayOf(PropTypes.shape()),
  systemMessage: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      dialogId: PropTypes.string,
    }),
  ]),
  userId: PropTypes.number.isRequired,
  placeHolder: PropTypes.string.isRequired,
  onTypeMessage: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  externalRender: PropTypes.func,
  whats: PropTypes.bool,
  EmptyListMessage: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  AttachmentsButton: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  mediaProgress: PropTypes.shape(),
  boticario: PropTypes.bool,
  hideInput: PropTypes.bool,
};

export default ChatScreen;
