// eslint-disable-next-line no-unused-vars
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { xmppStatus } from '@netlolo/core-redux/src/Actions';
import { WhatsAppService } from './WhatsAppService';

const Main = ({
  user,
  initStatus,
  children,
}) => {
  const socket = useContext(WhatsAppService);

  useEffect(() => {
    socket.init(user);
    setTimeout(() => {
      socket.login();
    }, 10000);
    initStatus(true);
  }, []);

  if (!user) {
    return children;
  }

  return children;
};

Main.defaultProps = {
  initStatus: () => {},
  children: null,
};

Main.propTypes = {
  user: PropTypes.string.isRequired,
  initStatus: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

const mapDispatchToProps = (dispatch) => ({
  initStatus: (status) => dispatch(xmppStatus(status)),
});

export default connect(null, mapDispatchToProps)(Main);
