import { combineReducers } from 'redux';
import auth from './auth';
import dialogs from './dialogs';
import connection from './connection';
import connecting from './connecting';
import internetReachable from './internetReachable';
import messages from './messages';
import selected from './selected';
import user from './user';
import typing from './typing';
import systemMessages from './systemMessages';
import fab from './fab';
import history from './history';
import extraData from './extraData';
import xmppStatus from './xmppStatus';
import mediaProgress from './mediaProgress';

export default combineReducers({
  auth,
  connection,
  connecting,
  dialogs,
  internetReachable,
  messages,
  selected,
  user,
  typing,
  systemMessages,
  fab,
  history,
  extraData,
  xmppStatus,
  mediaProgress,
});
