import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import baseStyle from './AuthForm.styles';
import defaultTheme from '../../defaultTheme';

const AuthForm = ({
  theme,
  signin,
  title,
  loginPlaceholder,
  passwordPlaceholder,
  signinText,
}) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async () => {
    setSubmitting(true);
    await signin(login, password);
    setSubmitting(false);
  };
  const styles = baseStyle(theme);

  return (
    <View style={styles.loginContainer}>
      <Text style={styles.welcome}>{title}</Text>
      <View style={styles.inputContainer}>
        <TextInput
          placeholder={loginPlaceholder}
          placeholderTextColor={theme.colors.TEXT}
          underlineColorAndroid="rgba(0,0,0,0)"
          style={styles.input}
          value={login}
          onChangeText={setLogin}
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          testID="login"
        />
      </View>
      <View style={styles.inputContainer}>
        <TextInput
          placeholder={passwordPlaceholder}
          placeholderTextColor={theme.colors.TEXT}
          underlineColorAndroid="rgba(0,0,0,0)"
          style={styles.input}
          value={password}
          onChangeText={setPassword}
          textContentType="password"
          secureTextEntry
          testID="password"
        />
      </View>
      <View>
        <TouchableOpacity
          style={[styles.button, submitting && styles.buttonDisabled]}
          onPress={onSubmit}
          disabled={submitting}
        >
          <Text style={styles.buttonText}>{signinText}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

AuthForm.defaultProps = {
  theme: defaultTheme,
  title: '',
};

AuthForm.propTypes = {
  theme: PropTypes.shape(),
  signin: PropTypes.func.isRequired,
  title: PropTypes.string,
  loginPlaceholder: PropTypes.string.isRequired,
  passwordPlaceholder: PropTypes.string.isRequired,
  signinText: PropTypes.string.isRequired,
};

export default AuthForm;
