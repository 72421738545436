import React from 'react';
import { FlatList, View, Text, TouchableOpacity } from 'react-native';
import styles from './SystemMessageList.styles';

const ActionButton = ({ onPress, text }) => (
  <TouchableOpacity onPress={onPress} style={{ height: 40, borderColor: '#d0d0d0', borderRadius: 20, borderWidth: 1, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20, paddingVertical: 12, backgroundColor: '#FFFFFF' }}>
    <Text style={{ fontSize: 14, textAlign: 'center' }}>{text}</Text>
  </TouchableOpacity>
)

const SystemMessageList = ({ items, onPress }) => (
  <View style={{ backgroundColor: 'transparent', height: 50, alignItems: 'center', }}>
    <FlatList
      contentContainerStyle={styles.container}
      data={items}
      keyExtractor={({ name }) => name}
      renderItem={({ item }) => <ActionButton onPress={() => onPress(item.action)} text={item.name} />}
      horizontal
    />
  </View>
);

export default SystemMessageList;
