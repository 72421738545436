import { Dimensions, StyleSheet } from 'react-native';

const fullWidth = Dimensions.get('window').width;

export default (theme, whatsAppStyle, realWidth) => StyleSheet.create({
  container: {
    paddingHorizontal: whatsAppStyle ? 20 : 8,
    paddingVertical: whatsAppStyle ? 2 : 8,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  last: {
    paddingBottom: 12,
  },
  positionToLeft: {
    justifyContent: 'flex-start',
  },
  positionToRight: {
    justifyContent: 'flex-end',
  },
  message: {
    paddingTop: whatsAppStyle ? 6 : 8,
    paddingBottom: 5,
    paddingHorizontal: whatsAppStyle ? 6 : 8,
    borderRadius: 10,
  },
  messageWhatsAppStyle: {
    paddingLeft: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.14,
    shadowRadius: 0.9,
    elevation: 1,
  },
  messageToLeft: {
    maxWidth: (realWidth || fullWidth) - (whatsAppStyle ? 106 : 90),
    borderBottomLeftRadius: 2,
    backgroundColor: theme.colors.OTHER_MESSAGE_BG,
  },
  messageToRight: {
    maxWidth: (realWidth || fullWidth) - (whatsAppStyle ? 100 : 55),
    borderBottomRightRadius: 2,
    backgroundColor: theme.colors.MINE_MESSAGE_BG,
  },
  customMessage: {
    maxWidth: (realWidth || fullWidth) - (whatsAppStyle ? 124 : 90),
  },
  messageText: {
    fontSize: whatsAppStyle ? 16 : 14,
    marginBottom: 4,
    color: theme.colors.TEXT,
  },
  messageTextWhatsAppStyle: {
    marginBottom: 0,
    textAlign: 'left',
    ...(realWidth ? { maxWidth: realWidth - 120 } : {}),
  },
  selfToLeft: {
    alignSelf: 'flex-start',
  },
  selfToRight: {
    alignSelf: 'flex-start',
  },
  dateSent: {
    alignSelf: 'flex-start',
    paddingTop: 4,
    fontSize: (whatsAppStyle ? 12 : 11),
    color: theme.colors.TEXT_DETAILS,
  },
  status: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  statusWhatsAppStyle: {
    marginLeft: 'auto',
    paddingLeft: 12,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  list: {
    flexDirection: 'column',
    marginBottom: 18,
    marginRight: -40,
    ...(realWidth ? { maxWidth: realWidth - 128 } : {}),
  },
});
