import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  chatConnected,
  chatConnecting,
  fetchDialogs,
  sortDialogs,
  userLogin,
  userLogout,
  xmppStatus,
} from '@netlolo/core-redux/src/Actions';
import Contacts from '@netlolo/core-xmpp/src/services/ContactsDataService';
import ConnectyCube from '../../Helpers/ConnectyCube';
import AsyncStorage from '../../Helpers/AsyncStorage';
import Connection from '../../Helpers/Connection/Connection';
import signin from './signin';

const Main = ({
  appConfig,
  errorCatcher,
  email,
  password,
  customLoginFunction,
  user,
  isConnected,
  login,
  logout,
  initStatus,
  children,
  getChats,
}) => {
  const loginFunction = signin(login, logout, errorCatcher);

  useEffect(() => {
    ConnectyCube.init(...appConfig.connectyCubeConfig);
    initStatus(true);
    (async () => {
      if (user && user.email !== email) {
        getChats([]);
        userLogout();
        await AsyncStorage.setItem('persist:chatRoot', '');
        await AsyncStorage.setItem('@netlolo/chat-contacts', '');
        await AsyncStorage.setItem('CurrentUserData', '');
      }
      const result = await AsyncStorage.getItem('@netlolo/chat-contacts');
      const contacts = JSON.parse(result || '{}');
      Contacts.set(contacts);

      if (!user || !isConnected) {
        if (customLoginFunction) {
          customLoginFunction(email, password, loginFunction);
        } else {
          loginFunction(email, password);
        }
      }
    })();
  }, []);

  if (!user) {
    return children;
  }

  return (
    <Connection xmpp={ConnectyCube}>
      {children}
    </Connection>
  );
};

Main.defaultProps = {
  customLoginFunction: null,
  errorCatcher: () => {},
  user: false,
  isConnected: false,
  login: () => {},
  logout: () => {},
  initStatus: () => {},
  children: null,
};

Main.propTypes = {
  appConfig: PropTypes.shape().isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  errorCatcher: PropTypes.func,
  customLoginFunction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape,
  ]),
  user: PropTypes.shape(),
  isConnected: PropTypes.bool,
  login: PropTypes.func,
  logout: PropTypes.func,
  initStatus: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

const mapStateToProps = (state) => ({
  user: state.user,
  isConnected: state.connection,
  dialogsHistory: state.history,
});

const mapDispatchToProps = (dispatch) => ({
  connected: () => dispatch(chatConnected()),
  connecting: () => dispatch(chatConnecting()),
  getChats: (dialogs) => dispatch(fetchDialogs(dialogs)),
  sortChats: (message, count) => dispatch(sortDialogs(message, count)),
  login: (user) => dispatch(userLogin(user)),
  logout: () => dispatch(userLogout()),
  initStatus: (status) => dispatch(xmppStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
