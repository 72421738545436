import { TOGGLE_FAB } from '../Actions/fab';

export default (open = false, action) => {
  switch (action.type) {
    case TOGGLE_FAB:
      return action.open;
    default:
      return open;
  }
};
