import { StyleSheet, Platform } from 'react-native';

export default (theme, maxHeight) => StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.DEFAULT_BG,
    ...(Platform.OS === 'web' ? { maxHeight, minHeight: maxHeight } : {}),
  },
  activityIndicator: {
    position: 'absolute',
    alignSelf: 'center',
    paddingTop: 25,
  },
});
