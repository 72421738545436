import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppState, Platform } from 'react-native';
import { connect } from 'react-redux';
import {
  userLogin,
  fetchDialogs,
  sortDialogs,
  pushMessage,
  setTyping,
  setMessage,
  internetReachable as internetReachableAction,
  internetUnreachable,
  setExtraData,
} from '@netlolo/core-redux/src/Actions';
import {
  setMediaDone as setMediaDoneAction,
  setMediaProgress as setMediaProgressAction,
} from '@netlolo/core-redux/src/Actions/mediaProgress';
import User from '@netlolo/core-xmpp/src/services/UserService';
import setupListeners, {
  messageListener,
  notificationListener,
  systemMessageListener,
  onTyping,
} from './listeners';

const Root = ({
  // Props
  theme,
  autoLoginFailCallback,
  pushService,
  notificationIOS,
  AppRouter,
  extraData,
  navigation,

  // Props from state
  user,
  selected,
  dialogs,

  // Actions
  login,
  getChats,
  sortChats,
  addToHistory,
  typing,
  setSystemMessage,
  extraDataSetter,
  setMediaProgress,
  setMediaDone,
}) => {
  const [appIsActive, setAppIsActive] = useState(true);
  const [push, setPush] = useState({});
  const onSystemMessageListener = systemMessageListener({ setSystemMessage, getChats });
  const onNotificationListener = notificationListener({
    dialogs,
    theme,
    navigation,
    extraData,
  });
  const onMessageListener = messageListener({
    user,
    appIsActive,
    selected,
    addToHistory,
    sortChats,
    push,
    setMediaProgress,
    setMediaDone,
  });
  const onTypingMessage = onTyping(typing);

  if (extraData) {
    extraDataSetter(extraData);
  }

  useEffect(() => {
    AppState.addEventListener('change', (nextAppState) => {
      setAppIsActive(nextAppState === 'active');
    });

    User.autologin()
      .then(login)
      .catch(autoLoginFailCallback);

    if (Platform.OS === 'ios') {
      notificationIOS
        .getInitialNotification()
        .then(notificationListener({
          dialogs,
          navigation,
          theme,
          extraData,
        }));
    }
    setPush(pushService(onNotificationListener, user));
  }, []);

  useEffect(() => {
    setupListeners({
      onMessageListener,
      onSystemMessageListener,
      onTypingMessage,
    });
  }, [dialogs, appIsActive, selected]);

  return AppRouter;
};

Root.defaultProps = {
  theme: undefined,
  autoLoginFailCallback: console.log,
  pushService: () => {},
  user: {},
  selected: {},
  dialogs: [],
  login: () => {},
  getChats: () => {},
  sortChats: () => {},
  addToHistory: () => {},
  typing: () => {},
  setSystemMessage: () => {},
  extraDataSetter: () => {},
};

Root.propTypes = {
  theme: PropTypes.shape(),
  autoLoginFailCallback: PropTypes.func,
  pushService: PropTypes.func,
  AppRouter: PropTypes.node.isRequired,
  user: PropTypes.shape(),
  selected: PropTypes.shape(),
  dialogs: PropTypes.arrayOf(PropTypes.shape()),
  login: PropTypes.func,
  getChats: PropTypes.func,
  sortChats: PropTypes.func,
  addToHistory: PropTypes.func,
  typing: PropTypes.func,
  setSystemMessage: PropTypes.func,
  extraDataSetter: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  selected: state.selected,
  dialogs: state.dialogs,
});

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(userLogin(user)),
  getChats: (dialogs) => dispatch(fetchDialogs(dialogs)),
  sortChats: (message, count) => dispatch(sortDialogs(message, count)),
  addToHistory: (message) => dispatch(pushMessage(message)),
  typing: (payload) => dispatch(setTyping(payload)),
  setSystemMessage: (payload) => dispatch(setMessage(payload)),
  reachable: () => dispatch(internetReachableAction()),
  unreachable: () => dispatch(internetUnreachable()),
  extraDataSetter: (extraData) => dispatch(setExtraData(extraData)),
  setMediaProgress: (uuid, progress) => dispatch(setMediaProgressAction(uuid, progress)),
  setMediaDone: (uuid) => dispatch(setMediaDoneAction(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
