import { User as UserModel } from '@netlolo/core-models';
import ConnectyCube from './ConnectyCube';
import AsyncStorage from './AsyncStorage';
import CurrentUser from './CurrentUserDataService';
import Contacts from './ContactsDataService';

const usersRequest = async (xmpp, page = 1, params = {}) => {
  const result = await xmpp.users.get({ ...params, page });
  return result.items;
};

const getAllUsers = async (xmpp, params, total = 0, page = 1, items = []) => {
  if (total < 100 || ((page - 1) * 100) > total) {
    return items;
  }
  const filterParams = { ...params };
  const result = await usersRequest(xmpp, page, filterParams);
  return getAllUsers(xmpp, params, total, page + 1, [...items, ...result]);
};

class UserService {
  async signin(params) {
    const session = await this.createSession(params);
    const { user } = session;
    user.password = params.password;
    await CurrentUser.set(user);
    return user;
  }

  async signup(params) {
    await this.createSession();
    await this.register(params);
    const result = await this.login(params);
    const user = {
      ...result,
      password: params.password,
    };
    await CurrentUser.set(user);
    return user;
  }

  async autologin() {
    const data = await CurrentUser.get();
    if (!data) return null;

    const user = await this.signin({ email: data.email, password: data.password });
    return user;
  }

  async resetPassword(email) {
    await ConnectyCube.users.resetPassword(email);
  }

  async logout() {
    ConnectyCube.logout();
    ConnectyCube.chat.disconnect();
    await AsyncStorage.clear();
  }

  async createSession(user) {
    const userParam = user ? { ...user, user_id: user.id } : null;
    return ConnectyCube.createSession(userParam);
  }

  async login(params) {
    const user = await ConnectyCube.login(params);
    return user;
  }

  async register(params) {
    const user = await ConnectyCube.users.signup(params);
    return user;
  }

  async getUserById(id) {
    const user = await ConnectyCube.users.get(id);
    return user;
  }

  async listUsers(params) {
    const initialParams = { ...params };
    const result = await ConnectyCube.users.get(initialParams);
    const isList = !!result.items;
    const users = isList
      ? await getAllUsers(ConnectyCube, params, result.total_entries, 2, result.items)
      : [{ user: result }];
    const contacts = {};

    for (let i = 0; i < users.length; i++) {
      const user = new UserModel(users[i].user);

      if (CurrentUser.getProp('id') === user.id) {
        continue;
      }

      contacts[user.id] = user;
    }

    Contacts.set(contacts);

    return contacts;
  }

  async listUsersByIds(ids) {
    const users = await this.listUsers({
      per_page: 100,
      page: 1,
      filter: {
        field: 'id',
        param: 'in',
        value: ids || '',
      },
    });
    return users;
  }

  async listUsersByFullName(name) {
    const users = await this.listUsers({ per_page: 100, login: name });

    return Object.values(users);
  }
}

// create instance
const User = new UserService();

// lock instance
Object.freeze(User);

export default User;
