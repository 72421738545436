import React from 'react';
import {
  ScrollView,
  View,
  Text,
  Switch,
} from 'react-native';

const SettingsList = ({ borderColor, scrollViewProps, children }) => (
  <ScrollView contentContainerStyle={[scrollViewProps, { borderColor, borderWidth: 1, borderStyle: 'solid' }]}>
    {children}
  </ScrollView>
);

SettingsList.defaultProps = {
  borderColor: '#000000',
  scrollViewProps: {},
};

const Header = ({ headerStyle, headerText }) => (
  <View style={[headerStyle, { paddingHorizontal: 12, paddingVertical: 8 }]}>
    <Text>{headerText}</Text>
  </View>
);

Header.defaultProps = {
  headerStyle: {},
  headerText: '',
};

const Item = ({
  title,
  titleStyle,
  icon,
  hasSwitch,
  switchState,
  switchOnValueChange,
}) => (
  <View
    style={{
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#c8c7cc',
      flexDirection: 'row',
      paddingHorizontal: 12,
      paddingVertical: 8,
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
  >
    {icon}
    <Text style={[titleStyle, { marginLeft: 12 }]}>{title}</Text>
    {hasSwitch && <Switch onValueChange={switchOnValueChange} value={switchState} style={{ position: 'absolute', right: 12 }} />}
  </View>
);

Item.defaultProps = {
  title: '',
  titleStyle: {},
  icon: null,
  hasSwitch: false,
  switchState: false,
  switchOnValueChange: () => {},
};

SettingsList.Header = Header;
SettingsList.Item = Item;

export default SettingsList;
