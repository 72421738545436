import React from 'react';
import { Text } from 'react-native';
import styles from './Bold.style';

const Bold = ({ text }) => (
  <Text style={styles.bold}>
    {text}
  </Text>
);

export default Bold;
