import { SET_HISTORY, CLEAR_HISTORY } from '../Actions/history';
import {
  MARK_AS_DELIVERED,
  MARK_AS_READ,
  MARK_AS_SENT,
  PUSH_MESSAGE,
} from '../Actions/messages';
import { USER_LOGOUT } from '../Actions/user';

const uniq = (arr) => arr.filter((item, ix, base) => base.indexOf(item) === ix);

export default (history = {}, action) => {
  switch (action.type) {
    case CLEAR_HISTORY: {
      return {};
    }
    case PUSH_MESSAGE: {
      const { message } = action;
      const { dialog_id: dialogId } = message;
      const dialogHistory = history[dialogId] || [];
      const isNewMessage = dialogHistory.filter(({ id }) => id === message.id).length === 0;
      return {
        ...history,
        [dialogId]: isNewMessage ? [message, ...dialogHistory] : dialogHistory,
      };
    }
    case MARK_AS_SENT: {
      const { message } = action;
      const { extension: { dialog_id: dialogId } } = message;
      const dialogMessages = history[dialogId] || [];
      const state = dialogMessages.map((item) => {
        if (item.id === message.id) {
          return { ...item, sent: true };
        }
        return item;
      });

      return {
        ...history,
        [dialogId]: state,
      };
    }
    case MARK_AS_DELIVERED: {
      const { payload } = action;
      const { messageId, userId, dialogId } = payload;
      const dialogMessages = history[dialogId] || [];
      const state = dialogMessages.map((item) => {
        if (item.id === messageId) {
          return { ...item, delivered: uniq([...(item.delivered || []), Number(userId)]) };
        }
        return item;
      });

      return {
        ...history,
        [dialogId]: state,
      };
    }
    case MARK_AS_READ: {
      const { payload } = action;
      const { messageId, userId, dialogId } = payload;
      const dialogMessages = history[dialogId] || [];

      const state = dialogMessages.map((item) => {
        if (item.id === messageId) {
          return { ...item, read: uniq([...(item.read || []), Number(userId)]) };
        }
        return item;
      });

      return {
        ...history,
        [dialogId]: state,
      };
    }
    case SET_HISTORY: {
      const { dialogId, messages } = action;
      return { ...history, [dialogId]: messages };
    }

    case USER_LOGOUT:
      return {};

    default:
      return history;
  }
};
