import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProfileScreen } from '@netlolo/ui-base/src/Templates';
import Contacts from '@netlolo/core-xmpp/src/services/ContactsDataService';
import CurrentUser from '@netlolo/core-xmpp/src/services/CurrentUserDataService';
import ConnectyCube from '../../Helpers/ConnectyCube';

const Profile = ({ navigation }) => {
  const [mute, setMute] = useState(false);
  const dialog = navigation.getParam('dialog');
  const { name, occupants_ids: occupantsIds, id: dialogId } = dialog || {};
  const myData = {
    id: CurrentUser.getProp('id'),
    full_name: 'Você',
    avatar: CurrentUser.getProp('avatar'),
  };
  const occupants = occupantsIds
    .map(Contacts.get)
    .filter((item) => (
      item != null
      && item.id !== 402124
      && item.id !== 153393
      && item.id !== 1589092
    ));

  const muteToggle = async () => {
    const actualValue = mute;
    setMute(!mute);
    try {
      await ConnectyCube.chat.dialog.updateNotificationsSettings(dialogId, mute);
      setMute(!mute);
    } catch (_) {
      setMute(actualValue);
    }
  };

  useEffect(() => {
    (async () => {
      const result = await ConnectyCube.chat.dialog.getNotificationsSettings(dialogId);
      const { notifications: { enabled } } = result;
      setMute(enabled !== 1);
    })();
  }, []);

  return (
    <ProfileScreen
      title={name}
      occupants={[myData, ...occupants]}
      muteValue={mute}
      onMuteToggle={muteToggle}
    />
  );
};

Profile.propTypes = {
  navigation: PropTypes.shape().isRequired,
};

export default Profile;
