import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import moment from 'moment/moment';
import styles from './DialogLastDate.styles';
import 'moment/locale/es';
import 'moment/locale/pt-br';

const DialogLastDate = ({ lastDate }) => {
  const getTime = () => {
    const msgLastDate = new Date(lastDate);
    return moment(msgLastDate).fromNow();
  };

  return <Text testID="DialogScreen_Dialog_Timestamp" style={styles.time} numberOfLines={1}>{getTime()}</Text>;
};

DialogLastDate.defaultProps = {
  lastDate: null,
};

DialogLastDate.propTypes = {
  lastDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default DialogLastDate;
