// eslint-disable-next-line no-unused-vars
import React from 'react';
import { AppRegistry } from 'react-native';
import urlParse from 'url-parse';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const settingsFromUrl = (from) => {
  const url = urlParse(from, true);
  const options = {};

  if (url.query.user) {
    options.user = url.query.user;
  }

  if (url.query.account) {
    options.account = url.query.account;
  }

  if (url.pathname === '/WhatsApp') {
    options.whatsapp = true;
  }

  return options;
};

AppRegistry.registerComponent('netlolo-web', () => App);
AppRegistry.runApplication('netlolo-web', {
  // eslint-disable-next-line no-undef
  rootTag: document.getElementById('root'),
  initialProps: {
    settings: settingsFromUrl(window.location.href),
  },
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
