export const onReadStatusListener = (setAsRead) => (messageId, dialogId, userId) => {
  setAsRead({ messageId, dialogId, userId });
};

export const onSentStatusListener = (setAsSent) => (messageLost, messageSent) => {
  if (messageLost && !messageSent) {
    // TODO: Tratar erro.
  } else {
    setAsSent(messageSent);
  }
};

export const onDeliveredStatusListener = (setAsDelivered) => (messageId, dialogId, userId) => {
  setAsDelivered({ messageId, dialogId, userId });
};
