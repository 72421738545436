let typingTime;

export default ({
  dialog,
  connected,
  setMessageValue,
  xmpp,
}) => (auxSetMessage = null) => (value) => {
  const type = dialog.xmpp_type;
  clearTimeout(typingTime);

  if (connected) {
    xmpp.chat.sendIsTypingStatus(type === 'chat' ? dialog.user_id : dialog.room_jid);

    typingTime = setTimeout(() => {
      xmpp.chat.sendIsStopTypingStatus(type === 'chat' ? dialog.user_id : dialog.room_jid);
    }, 1500);
  }
  if (auxSetMessage) {
    auxSetMessage(value);
  } else {
    setMessageValue(value);
  }
};
