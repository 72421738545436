import React from 'react';
import PropTypes from 'prop-types';
import {
  createSwitchNavigator as createStackNavigator,
  getActiveChildNavigationOptions,
} from '@react-navigation/core';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import WhatsAppScreen from './WhatsAppScreen';

const checkValueType = (value) => {
  if (value == null) {
    return '';
  }

  if (typeof value === 'string') {
    return value;
  }

  return JSON.stringify(value);
};

const objToQueryParams = (params = {}) => (
  Object.keys(params)
    .map((key) => `${key}=${checkValueType(params[key])}`)
    .join('&')
);

const checkIsJson = (value) => {
  try {
    return JSON.parse(value);
  } catch (_) {
    return value;
  }
};

const navigationHeaderStyle = StyleSheet.create({
  base: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    borderBottomColor: '#a9a9a9',
    borderBottomWidth: 1,
    flexDirection: 'row',
    height: 50,
  },
  titleWrapper: {
    minWidth: 30,
  },
  titleText: {
    fontSize: 18,
    fontWeight: '400',
  },
  buttonText: {
    fontSize: 14,
  },
});

const NavigationHeaderLeft = ({ params = {}, navigation }) => (
  <View style={navigationHeaderStyle.titleWrapper}>
    <TouchableOpacity
      onPress={() => {
        if (params.route) {
          const backRoute = navigation.state.routes[navigation.state.index] || {};
          const backRouteParams = backRoute.params || {};
          const backParams = backRouteParams.backParams || {};
          navigation.navigate(params.route, { ...backParams });
        }
      }}
    >
      <Text style={navigationHeaderStyle.buttonText}>{params.text || ''}</Text>
    </TouchableOpacity>
  </View>
);

const NavigationHeaderTitle = ({ title }) => (
  <View style={navigationHeaderStyle.titleWrapper}>
    <Text style={navigationHeaderStyle.titleText}>{title || ''}</Text>
  </View>
);

const NavigationHeader = ({
  title,
  headerLeftTitle,
  headerRightTitle,
  headerTitle,
  headerRight,
  headerLeft,
  navigation,
}) => {
  const LeftComponent = headerLeft
    || (
      <NavigationHeaderLeft
        params={headerLeftTitle}
        navigation={navigation}
      />
    );

  const RightComponent = headerRight
    || (
      <View>
        <Text>{headerRightTitle || ''}</Text>
      </View>
    );

  const TitleComponent = headerTitle
    || <NavigationHeaderTitle title={title} />;

  return (
    <View style={navigationHeaderStyle.base}>
      {LeftComponent}
      {TitleComponent}
      {RightComponent}
    </View>
  );
};

const WhatsAppNavigator = createStackNavigator({
  WhatsApp: {
    screen: WhatsAppScreen,
    navigationOptions: {
      title: 'Chat',
    },
  },
}, {
  initialRouteKey: 'WhatsApp',
});

class WrappedWhatsAppNavigator extends React.Component {
  static router = WhatsAppNavigator.router;

  render() {
    const { navigation, settings } = this.props;
    const options = getActiveChildNavigationOptions(navigation);
    const { index } = navigation.state;
    const { params, routeName } = navigation.state.routes[index];
    const paramsAsQuery = objToQueryParams(params);
    navigation.getParam = (paramName, defaultValue) => {
      if (params && paramName in params) {
        return checkIsJson(params[paramName]);
      }

      return defaultValue;
    };
    // eslint-disable-next-line no-undef
    window.history.replaceState(params, routeName, [routeName, paramsAsQuery].join('?'));

    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <NavigationHeader navigation={navigation} {...options} />
        <WhatsAppNavigator navigation={navigation} />
      </>
    );
  }
}

WrappedWhatsAppNavigator.propTypes = {
  navigation: PropTypes.shape().isRequired,
};


export default WrappedWhatsAppNavigator;
