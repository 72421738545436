import { StyleSheet } from 'react-native';

export default theme => StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.LIGHT_BG,
  },

  container: {
    flex: 1,
    justifyContent: 'space-between',
    marginHorizontal: 20,
    paddingVertical: 20,
  },

  centerText: {
    paddingTop: 20,
    textAlign: 'center',
    fontSize: 16,
  },

  button: {
    backgroundColor: theme.colors.PRIMARY,
    alignSelf: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 20,
    borderRadius: 8,
  },

  buttonContainer: {
    flexDirection: 'row',
  },

  buttonText: {
    fontSize: 21,
    lineHeight: 21,
    color: theme.colors.LIGHT_TEXT,
  },

  buttonLoad: {
    paddingLeft: 10,
  },

  slideContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
