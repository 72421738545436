import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { ChatScreen as ChatTemplate } from '@netlolo/ui-base/src/Templates';
import { WhatsAppService } from './WhatsAppService';

let typingTimeout;

const WhatsAppChat = ({ theme, dialog, history, dialogs }) => {
  const socket = useContext(WhatsAppService);

  useEffect(() => {
    if (dialog.id.length > 0) {
      setTimeout(() => {
        socket.getDialogHistory(dialog.id);
      }, 10000);
    } else {
      setTimeout(() => {
        socket.createDialog();
      }, 10000);
    }
  }, [dialog]);

  return (
    <View style={{ height: 448 }}>
      <ChatTemplate
        theme={theme}
        inProgress={false}
        history={history}
        userId="5511992462327@c.us"
        placeHolder="Digite sua mensagem..."
        onTypeMessage={(setInput) => (value) => {
          setInput(value);
          socket.sendTyping(dialog.id);
          if (typingTimeout) {
            clearTimeout(typingTimeout);
          }
          typingTimeout = setTimeout(() => {
            socket.stopTyping(dialog.id);
          }, 1500);
        }}
        sendMessage={(value, setInput) => {
          if (value.length > 0) {
            socket.sendMessage(dialog.id, value);
          }
          setInput('');
        }}
        whats
        boticario
      />
    </View>
  );
};

WhatsAppChat.defaultProps = {
  theme: undefined,
  // dialog: { id: '5511992462327-1599019711@g.us' },
  dialog: null,
};

WhatsAppChat.propTypes = {
  theme: PropTypes.shape(),
  dialog: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  dialog: state.selected,
  history: state.messages,
  dialogs: state.dialogs,
});

export default connect(mapStateToProps)(WhatsAppChat);
