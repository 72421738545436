import { Dialog } from '@netlolo/core-models';
import { SELECT_DIALOG, UNSELECT_DIALOG } from '../Actions/selected';

export default (dialog = new Dialog(), action) => {
  switch (action.type) {
    case SELECT_DIALOG: {
      return { ...action.dialog };
    }

    case UNSELECT_DIALOG: {
      return new Dialog();
    }

    default:
      return dialog;
  }
};
