import { StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    fontSize: 16,
    color: theme.colors.CHAT_TITLE,
  },
  action: {
    fontSize: 12,
    color: theme.colors.PRIMARY,
  },
});
