import { StyleSheet } from 'react-native';

export default theme => StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 30,
    borderColor: '#f0f0f0',
    borderWidth: 1,
    borderRadius: 12,
    margin: 2,
  },
  bold: {
    backgroundColor: theme.colors.PRIMARY,
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  text: {
    color: '#007bff',
    textAlign: 'center',
  },
});
