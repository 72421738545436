import React from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity } from 'react-native';
import { DialogTitles, ProfileIcon } from '../../Molecules';
import { EmptyCheck, Checked } from '../../Atoms/Icon';
import baseStyle from './Dialog.styles';
import defaultTheme from '../../defaultTheme';

const Dialog = ({
  theme,
  id,
  toChat,
  avatar,
  unreadCount,
  name,
  lastMessage,
  checkTyping,
  typingText,
  lastDate,
  index,
  edit,
  selected,
  onSelect,
  loading,
}) => {
  const styles = baseStyle(theme);

  return (
    <TouchableOpacity onPress={toChat}>
      <View style={styles.container} testID={`DialogScreen_Dialog-${index}`}>
        {edit && (
          <TouchableOpacity disabled={loading} onPress={() => onSelect(id)} style={{ alignSelf: 'center', marginRight: 6 }}>
            {(selected
              ? <Checked width={40} height={40} color="#454545" />
              : <EmptyCheck width={40} height={40} color="#c0c0c0" />
            )}
          </TouchableOpacity>
        )}
        <ProfileIcon
          testID="DialogScreen_Dialog_ProfileIcon"
          theme={theme}
          photo={avatar}
          unreadCount={unreadCount}
          name={name}
          iconSize="large"
        />
        <View style={styles.border}>
          <DialogTitles
            theme={theme}
            name={name}
            message={lastMessage}
            typing={checkTyping}
            typingText={typingText}
            lastDate={lastDate}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

Dialog.defaultProps = {
  theme: defaultTheme,
  avatar: null,
  unreadCount: null,
  lastMessage: '',
  checkTyping: false,
  lastDate: null,
  updatedDate: undefined,
};

Dialog.propTypes = {
  theme: PropTypes.shape(),
  toChat: PropTypes.func.isRequired,
  avatar: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  unreadCount: PropTypes.number,
  name: PropTypes.string.isRequired,
  lastMessage: PropTypes.string,
  checkTyping: PropTypes.bool,
  typingText: PropTypes.string.isRequired,
  lastDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  updatedDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  index: PropTypes.number.isRequired,
};

export default Dialog;
