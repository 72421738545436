export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const PUSH_MESSAGE = 'PUSH_MESSAGE';
export const MARK_AS_SENT = 'MARK_AS_SENT';
export const MARK_AS_DELIVERED = 'MARK_AS_DELIVERED';
export const MARK_AS_READ = 'MARK_AS_READ';

export const fetchMessages = (history) => ({ type: FETCH_MESSAGES, history });
export const pushMessage = (message) => ({ type: PUSH_MESSAGE, message });
export const markAsSent = (message) => ({ type: MARK_AS_SENT, message });
export const markAsDelivered = (payload) => ({ type: MARK_AS_DELIVERED, payload });
export const markAsRead = (payload) => ({ type: MARK_AS_READ, payload });
