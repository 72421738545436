import User from '@netlolo/core-xmpp/src/services/UserService';

export default (login, logout, errorCatcher = () => {}) => async (email, password) => {
  try {
    const res = await User.signin({ email, password });
    if (res) {
      return login(res);
    }
    errorCatcher(res);

    return null;
  } catch (e) {
    errorCatcher(e);
    if (e.code === 401) {
      logout();
    }
    return e;
  }
};
