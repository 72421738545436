import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './ProductSummaryWide.styles';
import { P } from '../Typography';
import Money from '../Money';

const colors = {
  PRIMARY: '#CF3D3A',
  TITLE: '#6F6F6F',
  SUB_TITLE: '#B2B2B2',
  TEXT: '#B2B2B2',
  TEXT_LIGHT: '#FFFFFF',
  TEXT_DARK: '#6F6F6F',
  BORDER_COLOR: '#F1F1F1',
  DEFAULT_BACKGROUND: '#FFFFFF',
  COMPONENT_BACKGROUND: '#FFFFFF',
  CONTRAST_BACKGROUND: '#FAFAFA',
  DARK_BACKGROUND: '#EEEEEE',
  ICON: '#6F6F6F',
  ICON_LIGHT: '#FFFFFF',
  GREEN: '#26AD4B',
  BLUE: '#5489E7',
  ERROR: '#CF3D3A',
};

const ProductSummaryWide = ({ title, price, minimumOrder }) => (
  <View style={styles.container}>
    <View style={styles.productInformation}>
      <P color={colors.TEXT}>{title}</P>
      <P color={colors.TEXT_DARK} style={styles.price}>
        {price && price.value !== 0
          ? <Money amount={price} />
          : <P>Preço sob consulta</P>}
      </P>
    </View>

    <View style={styles.sellerInformation}>
      {minimumOrder !== 0 && (
        <P small style={styles.minimumOrder}>{`ped. mín. loja:\n${minimumOrder} peças`}</P>
      )}
    </View>
  </View>
);

ProductSummaryWide.defaultProps = {
  price: null,
  minimumOrder: 0,
};

ProductSummaryWide.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.number,
  }),
  minimumOrder: PropTypes.number,
};

export default ProductSummaryWide;
