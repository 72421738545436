import { StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.LIGHT_BG,
  },
  noChats: {
    position: 'absolute',
    alignSelf: 'center',
    top: '42%',
  },
  noChatsText: {
    fontSize: 20,
  },
  search: {
    height: 50,
    backgroundColor: '#cacaca',
    padding: 8,
    flexDirection: 'row',
  },
  searchInput: {
    borderRadius: 10,
    backgroundColor: '#ffffff',
    padding: 6,
    fontSize: 18,
    flex: 0.7,
  },
});
