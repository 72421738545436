import { StyleSheet } from 'react-native';

export default theme => StyleSheet.create({
  container: {
    backgroundColor: theme.colors.LIGHT_BG,
    borderBottomWidth: 1,
    borderColor: theme.colors.DEFAULT_BORDER,
    zIndex: 1,
  },

  header: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },

  text: {
    justifyContent: 'center',
    flexGrow: 1,
    flexShrink: 1,
  },

  title: {
    color: theme.colors.TEXT,
    fontSize: 16,
    textAlign: 'center',
  },
});
