import { SET_MEDIA_PROGRESS, SET_MEDIA_DONE } from '../Actions/mediaProgress';

export default (mediaProgress = {}, action) => {
  switch (action.type) {
    case SET_MEDIA_PROGRESS:
      return {
        ...mediaProgress,
        [action.uuid]: action.progress,
      };
    case SET_MEDIA_DONE:
      return {
        ...mediaProgress,
        [action.uuid]: 1,
      };
    default:
      return mediaProgress;
  }
};
