import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import defaultTheme from '../../defaultTheme';
import baseStyle from './ChatTitle.styles';

const ChatTitle = ({ theme, title, action }) => {
  const styles = baseStyle(theme);

  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.action}>
        {action}
      </Text>
    </View>
  );
};

ChatTitle.defaultProps = {
  theme: defaultTheme,
  action: '',
};

ChatTitle.propTypes = {
  theme: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default ChatTitle;
