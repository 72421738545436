import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const InitChat = ({
  user,
  Base,
  WithChat,
  xmppStatus,
}) => (
  <>
    {((user && xmppStatus) ? WithChat : Base)}
  </>
);

InitChat.defaultProps = {
  user: null,
  xmppStatus: false,
};

InitChat.propTypes = {
  user: PropTypes.shape(),
  Base: PropTypes.node.isRequired,
  WithChat: PropTypes.node.isRequired,
  xmppStatus: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.user,
  xmppStatus: state.xmppStatus,
});

export default connect(mapStateToProps)(InitChat);
