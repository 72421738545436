import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import FeedProduct from './UI/FeedProduct';

const SpecialMessageCard = ({ product, seller }) => (
  <View style={{ flex: 1 }}>
    <FeedProduct
      product={product}
      seller={seller}
      onProductPress={() => {}}
    />
  </View>
);

SpecialMessageCard.propTypes = {
  product: PropTypes.shape().isRequired,
  seller: PropTypes.shape().isRequired,
};

export default SpecialMessageCard;
