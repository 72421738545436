import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    backgroundColor: '#f2f2f2',
  },
  avatar: {
    width: '100%',
    height: 150,
  },
  wrapper: {
    height: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  description: {
    fontSize: 12,
  },
  muteGroup: {
    marginTop: 15,
  },
  icon: {
    marginLeft: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  occupantsGroup: {
    color: '#88878a',
    marginTop: 50,
    marginLeft: 12,
    fontSize: 13,
  },
  avatarIcon: {
    marginRight: -8,
  },
  avatarName: {
    fontSize: 16,
  },
});
