import { SET_TYPING } from '../Actions/typing';

export default (typing = {}, action) => {
  switch (action.type) {
    case SET_TYPING: {
      const { dialogId, userId, isTyping } = action.payload;
      return {
        ...typing,
        [dialogId]: {
          ...(typing[dialogId] || {}),
          [userId]: isTyping,
        },
      };
    }

    default:
      return typing;
  }
};
