import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import ConnectyCube from 'connectycube';
import {
  createSwitchNavigator as createStackNavigator,
  getActiveChildNavigationOptions,
} from '@react-navigation/core';
import { connect } from 'react-redux';
import { ProfileScreen } from '@netlolo/core-chat/src/Screens';
import BigScreen from '@netlolo/core-chat/src/Screens/BigScreen/BigScreen';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import ChatBase from './ChatBase';
import externalRender from './netloloSpecialRender';
import theme from './theme';

const titleStyles = StyleSheet.create({
  wrapper: {
    color: 'rgba(0, 0, 0, 0.9)',
    marginHorizontal: 16,
    textAlign: 'left',
  },
  title: {
    fontSize: 18,
    fontWeight: '400',
  },
  subtitle: {
    fontSize: 12,
  },
});

const Title = ({ title, subtitle, onPress }) => (
  <View style={titleStyles.wrapper}>
    {
      onPress
        ? (
          <TouchableOpacity onPress={onPress}>
            <Text testID="Title_title" style={titleStyles.title}>{title}</Text>
          </TouchableOpacity>
        ) : <Text testID="Title_title" style={titleStyles.title}>{title}</Text>
    }
    {subtitle && <Text testID="Title_subtitle" style={titleStyles.subtitle}>{subtitle}</Text>}
  </View>
);

Title.defaultProps = {
  title: '',
  subtitle: null,
  onPress: null,
};

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onPress: PropTypes.func,
};

const checkTitle = (base, dialog, key) => {
  if (base.length > 0) {
    return base;
  }
  if (dialog.id.length === 0) {
    return '';
  }

  return dialog[key] ? `${dialog[key]} - ${dialog.id}` : '';
};

const HeaderTitle = ({ title, isConnecting, onPress, selectedDialog, titleFromKey }) => (
  <Title
    onPress={onPress}
    title={checkTitle(title, selectedDialog, titleFromKey)}
    subtitle={isConnecting ? 'Conectando...' : null}
  />
);

HeaderTitle.defaultProps = {
  title: '',
  isConnecting: false,
  onPress: null,
};

HeaderTitle.propTypes = {
  title: PropTypes.string,
  isConnecting: PropTypes.bool,
  onPress: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isConnecting: state.connecting,
  selectedDialog: state.selected,
});

const ConnectedTitle = connect(mapStateToProps)(HeaderTitle);

const DoubleHeader = ({ left, right }) => (
  <View style={{ width: '100vw', margin: 0, paddingHorizontal: -12, height: 50, flexDirection: 'row' }}>
    <View style={{ width: '30vw', height: 50, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: theme.colors.BORDER_COLOR, alignItems: 'center', justifyContent: 'center' }}>
      {left}
    </View>
    <View style={{ width: '70vw', height: 50, alignItems: 'center', justifyContent: 'center' }}>
      {right}
    </View>
  </View>
);

const FileUpload = ({ sendMessage }) => {
  const [loading, setLoading] = useState(false);
  let inputRef;

  const onPress = () => {
    inputRef.click();
    setLoading(true);
  };

  const onChange = async ({ target: { files } }) => {
    const [inputFile] = files || [];
    const fileParams = {
      name: inputFile.name,
      file: inputFile,
      type: inputFile.type,
      size: inputFile.size,
      public: false,
    };

    const result = await ConnectyCube.storage.createAndUpload(fileParams);
    const { uid } = result;
    sendMessage('Anexo', null, { uid, type: 'photo' });
    setLoading(false);

    return result;
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={{ height: 40, width: '100%', border: '1px solid #cacaca', borderRadius: 8, backgroundColor: '#ffffff', alignItems: 'center', justifyContent: 'center', marginTop: 6 }}>
        <Text>{loading ? 'Enviando...' : 'Enviar Foto'}</Text>
        <input ref={(ref) => { inputRef = ref; }} onChange={onChange} type="file" style={{ display: 'none' }} />
      </View>
    </TouchableOpacity>
  );
};

const EmojiSelector = ({ messageValue, setMessageValue, sendMessage }) => {
  const [open, toggle] = useState(false);
  const [selectedEmoji, setEmoji] = useState(null);

  const onEmojiClick = (_, { emoji }) => {
    setEmoji(emoji);
  };

  useEffect(() => {
    if (selectedEmoji) {
      setMessageValue(`${messageValue}${selectedEmoji}`);
      setEmoji(null);
    }
  }, [selectedEmoji, messageValue, setMessageValue]);

  return (
    <View>
      <TouchableOpacity
        onPress={() => toggle(!open)}
        style={{ marginLeft: 8, height: 32, width: 32, alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff', borderRadius: 8 }}
      >
        <Text style={{ fontSize: 22 }}>+</Text>
      </TouchableOpacity>
      {open && (
        <View style={{ position: 'absolute', bottom: 40, left: 8 }}>
          <Picker onEmojiClick={onEmojiClick} skinTone={SKIN_TONE_MEDIUM_DARK} />
          <FileUpload sendMessage={sendMessage} />
        </View>
      )}
    </View>
  );
};

EmojiSelector.propTypes = {
  messageValue: PropTypes.string.isRequired,
  setMessageValue: PropTypes.func.isRequired,
};

const MainNavigator = createStackNavigator({
  BigScreen: {
    screen: (props) => (
      <BigScreen
        theme={theme}
        externalRender={externalRender}
        AttachmentsButton={EmojiSelector}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ),
    navigationOptions: ({ navigation }) => ({
      title: 'Chat',
      headerTitle: (
        <DoubleHeader
          left={<Text style={{ fontSize: 18, fontWeight: '400' }}>Chat</Text>}
          right={<ConnectedTitle titleFromKey="name" />}
        />
      ),
      headerLeft: (<View />),
    }),
  },
  DialogProfile: {
    screen: ProfileScreen,
    navigationOptions: ({ navigation }) => ({
      title: 'Dados do contato',
      headerLeftTitle: {
        text: '← Voltar',
        route: 'BigScreen',
      },
    }),
  },
}, {
  initialRouteKey: 'BigScreen',
});

const navigationHeaderStyle = StyleSheet.create({
  base: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    borderBottomColor: '#a9a9a9',
    borderBottomWidth: 1,
    flexDirection: 'row',
    height: 50,
  },
  titleWrapper: {
    minWidth: 30,
  },
  titleText: {
    fontSize: 18,
    fontWeight: '400',
  },
  buttonText: {
    fontSize: 14,
  },
});

const NavigationHeaderLeft = ({ params = {}, navigation }) => (
  <View style={navigationHeaderStyle.titleWrapper}>
    <TouchableOpacity
      onPress={() => {
        if (params.route) {
          const backRoute = navigation.state.routes[navigation.state.index] || {};
          const backRouteParams = backRoute.params || {};
          const backParams = backRouteParams.backParams || {};
          navigation.navigate(params.route, { ...backParams });
        }
      }}
    >
      <Text style={navigationHeaderStyle.buttonText}>{params.text || ''}</Text>
    </TouchableOpacity>
  </View>
);

const NavigationHeaderTitle = ({ title }) => (
  <View style={navigationHeaderStyle.titleWrapper}>
    <Text style={navigationHeaderStyle.titleText}>{title || ''}</Text>
  </View>
);

const NavigationHeader = ({
  title,
  headerLeftTitle,
  headerRightTitle,
  headerTitle,
  headerRight,
  headerLeft,
  navigation,
}) => {
  console.log({ headerLeftTitle, headerRightTitle, title });
  const LeftComponent = headerLeft
    || (
      <NavigationHeaderLeft
        params={headerLeftTitle}
        navigation={navigation}
      />
    );

  const RightComponent = headerRight
    || (
      <View>
        <Text>{headerRightTitle || ''}</Text>
      </View>
    );

  const TitleComponent = headerTitle
    || <NavigationHeaderTitle title={title} />;

  return (
    <View style={navigationHeaderStyle.base}>
      {LeftComponent}
      {TitleComponent}
      {RightComponent}
    </View>
  );
};

const checkValueType = (value) => {
  if (value == null) {
    return '';
  }

  if (typeof value === 'string') {
    return value;
  }

  return JSON.stringify(value);
};

const objToQueryParams = (params = {}) => (
  Object.keys(params)
    .map((key) => `${key}=${checkValueType(params[key])}`)
    .join('&')
);

const checkIsJson = (value) => {
  try {
    return JSON.parse(value);
  } catch (_) {
    return value;
  }
};

class WrappedMainNavigator extends React.Component {
  static router = MainNavigator.router;

  render() {
    const { navigation, settings } = this.props;
    const options = getActiveChildNavigationOptions(navigation);
    const { index } = navigation.state;
    const { params, routeName } = navigation.state.routes[index];
    const paramsAsQuery = objToQueryParams(params);
    navigation.getParam = (paramName, defaultValue) => {
      if (params && paramName in params) {
        return checkIsJson(params[paramName]);
      }

      return defaultValue;
    };
    window.history.replaceState(params, routeName, [routeName, paramsAsQuery].join('?'));

    return (
      <ChatBase navigation={navigation} settings={settings}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <NavigationHeader navigation={navigation} {...options} />
        <MainNavigator navigation={navigation} />
      </ChatBase>
    );
  }
}

WrappedMainNavigator.propTypes = {
  navigation: PropTypes.shape().isRequired,
};

export default WrappedMainNavigator;
