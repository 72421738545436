import { Platform, StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: Platform.OS === 'ios' ? theme.colors.DEFAULT_BORDER : 'transparent',
    minHeight: 44,
    backgroundColor: Platform.OS === 'ios' ? '#fbfbfb' : 'transparent',
    paddingTop: 6,
    paddingBottom: 6,
  },
  textInput: {
    flex: 1,
    fontSize: 15,
    fontWeight: '300',
    borderRadius: 25,
    marginHorizontal: 8,
    backgroundColor: theme.colors.MESSAGE_INPUT_BG,
    alignSelf: 'center',
    borderColor: '#cacaca',
    borderWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 0,
  },
  button: {
    width: Platform.OS === 'ios' ? 44 : 38,
    height: Platform.OS === 'ios' ? 44 : 38,
    paddingLeft: 4,
    marginHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Platform.OS === 'ios' ? 'transparent' : theme.colors.PRIMARY,
    borderRadius: 19,
  },
});
