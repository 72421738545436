import React from 'react';
import { View, Text } from 'react-native';
import styles from './OfflineNotice.styles';

const  OfflineView = ({ error, children }) => {
  const message = children || 'Sem conexão com a internet :(';
  return (
    <View style={[styles.container, error && styles.error]}>
      <Text style={[styles.text, error && styles.textError]}>{message}</Text>
    </View>
  );
};

const OfflineNotice = ({ network }) => {
  if (!network) {
    return <OfflineView error />;
  }

  return null;
};

export default OfflineNotice;
