import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Checkmark, DoneAll, Timer } from '../Icon';

const Check = ({ sent, delivered, read, mine }) => {
  if (!mine) {
    return false;
  }

  if (read) {
    return (
      <View accessible={true} accessibilityLabel="Lida" style={{ margin: -2 }}>
        <DoneAll color="#0AC160" height={24} width={24} />
      </View>
    );
  }

  if (delivered) {
    return (
      <View accessible={true} accessibilityLabel="Recebida" style={{ margin: -2 }}>
        <DoneAll color="#858C92" height={24} width={24} />
      </View>
    );
  }

  if (sent) {
    return (
      <View accessible={true} accessibilityLabel="Enviada" style={{ margin: -2 }}>
        <Checkmark color="#858c92" height={26} width={26} />
      </View>
    );
  }

  return (
    <View accessible={true} accessibilityLabel="Enviando..." style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
      <Timer color="#0AC160" height={15} width={15} />
    </View>
  );
};

Check.defaultProps = {
  sent: false,
  delivered: false,
  read: false,
  mine: false,
};

Check.propTypes = {
  sent: PropTypes.bool,
  delivered: PropTypes.bool,
  read: PropTypes.bool,
  mine: PropTypes.bool,
};

export default Check;
