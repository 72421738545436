import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import CardImage from '../CardImage';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import { ProductSummaryWide } from '../ProductSummary';
import styles from './FeedProduct.styles';

const FeedProduct = ({
  seller,
  product,
  onProfilePress,
  onProductPress,
}) => (
  <>
    <View style={styles.headerContainer}>
      <TouchableOpacity
        onPress={onProfilePress}
        activeOpacity={0.8}
        delayPressIn={50}
        style={styles.profileWrapper}
      >
        <ProfileHeader
          name={seller.name}
          image={seller.image}
          polo={seller.polo}
          subpolo={seller.subpolo}
        />
      </TouchableOpacity>
    </View>

    <View style={styles.cardContainer}>
      <CardImage
        image={(product.media_gallery_entries[0] || {}).original}
        onPress={onProductPress}
      >
        <ProductSummaryWide
          title={product.name}
          price={product.price && product.price.regularPrice.amount}
          minimumOrder={product.minimumOrder}
        />
      </CardImage>
    </View>
  </>
);

FeedProduct.defaultProps = {
  onProfilePress: () => {},
  followEnable: false,
};

FeedProduct.propTypes = {
  seller: PropTypes.shape({
    name: ProfileHeader.propTypes.name,
    image: ProfileHeader.propTypes.image,
    polo: ProfileHeader.propTypes.polo,
    subpolo: ProfileHeader.propTypes.subpolo,
  }).isRequired,
  product: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.shape({
      regularPrice: PropTypes.shape({
        amount: PropTypes.shape({
          currency: PropTypes.string,
          value: PropTypes.number,
        }),
      }),
    }),
    media_gallery_entries: PropTypes.arrayOf(PropTypes.shape({
      thumb: PropTypes.shape({
        uri: PropTypes.string,
      }),
    })),
    minimumOrder: PropTypes.number,
  }).isRequired,
  onProfilePress: PropTypes.func,
  onProductPress: PropTypes.func.isRequired,
  followEnable: PropTypes.bool,
};

export default FeedProduct;
