import { SET_SYSTEM_MESSAGE } from '../Actions/systemMessages';

export default (message = {}, action) => {
  switch (action.type) {
    case SET_SYSTEM_MESSAGE:
      return action.message;

    default:
      return message;
  }
};
