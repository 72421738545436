import isObject from 'isobject';

const defaultDialog = {
  id: '',
  type: '',
  xmpp_type: '',
  room_jid: '',
  name: 'Unknown',
  photo: null,
  description: '',
  destination: null,
  user_id: 0,
  admins_ids: [],
  occupants_ids: [],
  updated_date: Date.now(),
  last_message_date_sent: Number(Date.now()),
  last_message: '',
  last_message_id: '',
  last_message_user_id: 0,
  unread_messages_count: 0,
  unread_messages_ids: [],
  pinned_messages_ids: [],
  archived: false,
};

const isJson = (str) => {
  try {
    return [null, JSON.parse(str)];
  } catch (err) {
    return [err];
  }
};

const getSpecialMessage = (json) => {
  if (json.items !== undefined) {
    return json.items.map(({ name }) => name).join(', ');
  }

  return 'Anexo';
};

const getXMPPType = (type) => {
  if (type === 3) {
    return 'chat';
  }

  if (type) {
    return 'groupchat';
  }

  return '';
};

const getData = (data) => {
  if (!data) {
    return {};
  }
  const { class_name: type, users } = data;

  return {
    [type]: (users || []).map((item) => JSON.parse(item)),
  };
};

const getDate = (date) => {
  if (!date) {
    return null;
  }
  const timestamp = Number(new Date(date));
  const { length } = timestamp.toString();
  const hasMili = length >= 13;
  const realTimestamp = hasMili ? Number(timestamp) : Number(timestamp) * 1000;
  return Number(new Date(realTimestamp));
};

const getLastDate = (messageAt, updatedAt, createdAt) => {
  if (!messageAt) {
    return getDate(updatedAt || createdAt);
  }

  return getDate(messageAt);
};

export default class Dialog {
  constructor(dialog = defaultDialog, archivedList = []) {
    const [err, json] = isJson(dialog.last_message);
    const lastMessage = (err === null && isObject(json))
      ? getSpecialMessage(json)
      : dialog.last_message;

    // eslint-disable-next-line no-underscore-dangle
    this.id = dialog._id || dialog.id;
    this.type = dialog.type;
    this.xmpp_type = getXMPPType(dialog.type);
    this.room_jid = dialog.xmpp_room_jid || dialog.room_jid;
    this.name = dialog.name;
    this.photo = dialog.photo && { uri: dialog.photo };
    this.description = dialog.description;
    this.destination = dialog.xmpp_room_jid || dialog.room_jid;
    this.user_id = dialog.user_id;
    this.admins_ids = dialog.admins_ids;
    this.occupants_ids = dialog.occupants_ids;
    this.updated_date = getDate(dialog.updated_at);
    this.last_message_date_sent = getLastDate(
      dialog.last_message_date_sent,
      dialog.updated_at,
      dialog.created_at,
    );
    this.last_message = lastMessage || 'Não há mensagens.';
    this.last_message_id = dialog.last_message_id;
    this.last_message_user_id = dialog.last_message_user_id;
    this.unread_messages_count = dialog.unread_messages_count;
    this.unread_messages_ids = dialog.unread_messages_ids;
    this.pinned_messages_ids = dialog.pinned_messages_ids;
    this.data = getData(dialog.data);
    this.archived = archivedList.indexOf(dialog._id || dialog.id) !== -1;
  }
}
