import React from 'react';
import { Text, Linking, Platform } from 'react-native';
import styles from './HyperLink.style';

const goToURL = (url) => {
  const link = url.startsWith('http') || url.startsWith('https') ? url : `https://${url}`;
  if (Platform.OS === 'web') {
    window.open(link, '_blank');
  } else {
    Linking.openURL(link);
  }
};

const HyperLink = ({ url }) => (
  <Text style={styles.link} onPress={() => goToURL(url)}>
    {url}
  </Text>
);

export default HyperLink;
