import { StyleSheet } from 'react-native';

const units = {
  DEFAULT_PADDING: 15,
  GUTTER: 7.5,
  TAB_ICON_SIZE: 14,
};

export default StyleSheet.create({
  cardContainer: {
    marginTop: units.DEFAULT_PADDING,
    height: '40vh',
    width: 'auto',
  },

  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  profileWrapper: {
    minWidth: '20vw',
  },

  followButtonContainer: {
    height: 25,
    paddingHorizontal: 20,
  },

  followButtonText: {
    fontSize: 14,
  },

  buttonWrapper: {
    alignItems: 'center',
  },
});
