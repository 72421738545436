import React, { useState } from 'react';
import { TouchableOpacity, View, Platform } from 'react-native';
import debounce from 'debounce';
import { Send } from '../Icon';
import AutoGrowingTextInput from './AutoGrowInput';
import baseStyles from './MessageInput.styles';
import defaultTheme from '../../defaultTheme';

const MessageInput = ({
  theme,
  placeholder,
  onTypeMessage,
  sendMessage,
  AttachmentsButton,
}) => {
  const [messageValue, setMessageValue] = useState('');
  const styles = baseStyles(theme);

  return (
    <View style={styles.container}>
      {AttachmentsButton && (
        <AttachmentsButton
          messageValue={messageValue}
          setMessageValue={onTypeMessage(setMessageValue)}
          sendMessage={sendMessage}
        />
      )}

      <AutoGrowingTextInput
        style={styles.textInput}
        placeholder={placeholder}
        value={messageValue}
        onChangeText={onTypeMessage(setMessageValue)}
        maxHeight={170}
        minHeight={40}
        sendMessage={() => debounce(sendMessage(messageValue, setMessageValue), 200)}
        clearInputFn={() => setMessageValue('')}
        enableScrollToCaret
      />
      <TouchableOpacity
        style={styles.button}
        onPress={() => debounce(sendMessage(messageValue, setMessageValue), 200)}
      >
        <Send
          color={Platform.OS === 'ios' ? theme.colors.PRIMARY : '#FFFFFF'}
          width={Platform.OS === 'ios' ? 32 : 24}
          height={Platform.OS === 'ios' ? 32 : 24}
        />
      </TouchableOpacity>
    </View>
  );
};

MessageInput.defaultProps = {
  theme: defaultTheme,
  AttachmentsButton: null,
};

export default MessageInput;
