import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import { DialogLastDate } from '../../Atoms';
import baseStyle from './DialogTitles.styles';
import defaultTheme from '../../defaultTheme';

const DialogTitles = ({
  theme,
  name,
  message,
  typing,
  typingText,
  lastDate,
}) => {
  const styles = baseStyle(theme);

  return (
    <View style={styles.container}>
      <Text style={styles.name} numberOfLines={1} testID="DialogScreen_Dialog_Title">{name}</Text>
      {
        typing
          ? <Text style={[styles.message, styles.typing]} numberOfLines={1}>{typingText}</Text>
          : <Text style={styles.message} numberOfLines={1} testID="DialogScreen_Dialog_Message">{message}</Text>
      }
      <DialogLastDate
        lastDate={lastDate}
      />
    </View>
  );
};

DialogTitles.defaultProps = {
  theme: defaultTheme,
  message: '',
  typing: false,
  lastDate: null,
  updatedDate: undefined,
};

DialogTitles.propTypes = {
  theme: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  message: PropTypes.string,
  typing: PropTypes.bool,
  typingText: PropTypes.string.isRequired,
  lastDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  updatedDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default DialogTitles;
