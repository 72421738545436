import React from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';
import styles, { textBase } from './Typography.styles';

const P = ({
  large,
  small,
  xsmall,
  color,
  bold,
  style,
  ...props
}) => (
  <Text
    style={[
      styles.paragraph,
      textBase({
        large,
        small,
        xsmall,
        color,
        bold,
      }),
      style,
    ]}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

P.defaultProps = {
  large: false,
  small: false,
  xsmall: false,
  bold: false,
  color: '#646464',
  style: {},
};

P.propTypes = {
  large: PropTypes.bool,
  small: PropTypes.bool,
  xsmall: PropTypes.bool,
  bold: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.shape(),
};

export default P;
