import { StyleSheet } from 'react-native';

export const largeIcon = theme => StyleSheet.create({
  photo: {
    borderRadius: 25,
    height: 50,
    width: 50,
    marginVertical: 10,
    marginRight: 10,
  },
  randomPhoto: {
    borderWidth: 0.5,
    borderColor: theme.colors.DEFAULT_BORDER,
    justifyContent: 'center',
    alignItems: 'center',
  },
  randomIcon: {
    fontSize: 25,
    fontWeight: '700',
    color: theme.colors.DEFAULT_BG,
  }
});

export const mediumIcon = theme => StyleSheet.create({
  photo: {
    borderRadius: 20,
    height: 40,
    width: 40,
    marginVertical: 10,
    marginRight: 10,
  },
  randomPhoto: {
    borderWidth: 0.5,
    borderColor: theme.colors.DEFAULT_BORDER,
    justifyContent: 'center',
    alignItems: 'center',
  },
  randomIcon: {
    fontSize: 20,
    fontWeight: '600',
    color: theme.colors.DEFAULT_BG,
  }
});

export const smallIcon = theme => StyleSheet.create({
  photo: {
    borderRadius: 15,
    height: 30,
    width: 30,
    marginRight: 5,
  },
  randomPhoto: {
    borderWidth: 0.5,
    borderColor: theme.colors.DEFAULT_BORDER,
    justifyContent: 'center',
    alignItems: 'center',
  },
  randomIcon: {
    fontSize: 18,
    fontWeight: '600',
    color: theme.colors.DEFAULT_BG,
  }
});
