import { StyleSheet } from 'react-native';

export default theme => StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingVertical: 10
  },
  name: {
    height: 18,
    fontSize: 14,
    fontWeight: '700'
  },
  message: {
    height: 15,
    marginTop: 3,
    fontSize: 12,
    fontWeight: '300'
  },
  typing: {
    color: theme.colors.PRIMARY,
  }
});
