import { XMPP_INIT_FINISHED } from '../Actions/xmppStatus';

export default (status = false, action) => {
  switch (action.type) {
    case XMPP_INIT_FINISHED:
      return action.status;
    default:
      return status;
  }
};
