import Chat from '@netlolo/core-xmpp/src/services/ChatService';
import User from '@netlolo/core-xmpp/src/services/UserService';
import resendMessages from './resendMessages';

const makeConnection = async ({
  // Props
  user,
  dialogsHistory,

  // Actions
  setConnected,
  getChats,
  sortChats,

  // Resources
  xmpp,
}) => {
  const session = xmpp.service.getSession();
  if (!session) {
    await User.createSession(user);
  }
  const dialogs = await Chat.getConversations();
  if (!xmpp.chat.isConnected) {
    await Chat.connect(user, dialogs);
    setConnected();
  } else {
    setConnected();
  }
  getChats(dialogs);
  resendMessages(dialogsHistory, dialogs, sortChats, user);
};

export default ({
  // Props
  isConnected,
  user,
  waitConnect,
  dialogsHistory,

  // Actions
  setConnected,
  setConnecting,
  setLoggedOut,
  setDisconnected,
  getChats,
  sortChats,
  setWaitConnect,

  // Resources
  xmpp,
}) => async () => {
  if (xmpp.chat) {
    const { socket } = xmpp.chat.xmppClient;
    const connect = async () => makeConnection({
      user,
      setConnected,
      getChats,
      dialogsHistory,
      sortChats,
      xmpp,
    });

    if ((user && !isConnected && !waitConnect) || !socket) {
      setWaitConnect(true);
      setConnecting();

      try {
        await connect();
      } catch (e) {
        console.warn('xmppConnect', e);

        if (e.code === 401 && e.detail && e.detail[0] === 'Unauthorized') {
          setLoggedOut();
        } else if (e.code === 422) {
          setDisconnected();
          await connect();
        }
      }

      setWaitConnect(false);
    }
  }
};
