import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  Image,
} from 'react-native';
import SwipeableViews from 'react-swipeable-views-native';
// TODO: Check what the actual fuck
// import Image from 'react-native-scalable-image';
import { Header } from '../../Molecules';
import baseStyle from './SliderScreen.styles';
import defaultTheme from '../../defaultTheme';

const SliderScreen = ({ theme, screens, position, loading }) => {
  const styles = baseStyle(theme);

  return (
    <View style={styles.wrapper}>
      <Header title={screens[position].title} />
      <View style={styles.container}>
        <SwipeableViews
          index={position}
          disabled
        >
          {screens.map((screen, index) => (
            <View
              key={screen.key}
              style={styles.slideContainer}
            >
              {screen.image && (
                <Image
                  width={Dimensions.get('window').width - 100} // height will be calculated automatically
                  source={screen.image}
                />
              )}
              <ScrollView>
                <Text style={styles.centerText}>{screen.text}</Text>
              </ScrollView>
            </View>
          ))}
        </SwipeableViews>

        <View>
            <TouchableOpacity
              onPress={screens[position].buttonPress}
              style={[styles.button, loading && { opacity: 0.7 }]}
              disabled={loading}
            >
              <View style={styles.buttonContainer}>
                <Text style={styles.buttonText}>{screens[position].buttonText}</Text>
                {loading && (
                  <ActivityIndicator style={styles.buttonLoad} size="small" color="#FFFFFF" />
                )}
              </View>
            </TouchableOpacity>
        </View>

      </View>
    </View>
  );
}

SliderScreen.defaultProps = {
  theme: defaultTheme,
};

SliderScreen.propTypes = {
  theme: PropTypes.shape(),
};

export default SliderScreen;
