import React, { useState } from 'react';
import { View } from 'react-native';

const ModalDropDown = ({ style, defaultValue, options, onSelect }) => {
  const [selected, setSelected] = useState(defaultValue);

  const onChange = ({ target: { value } }) => {
    setSelected(value);
    onSelect(null, value);
  };

  return (
    <>
      <View style={{ position: 'relative', ...style }}>
        <select
          value={selected}
          onChange={onChange}
          style={{
            appearance: 'none',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            height: 36,
            display: 'flex',
            width: 100,
            fontSize: 16,
            paddingLeft: 12,
          }}
        >
          {options.map((item) => (
            <option value={item} key={item}>{item}</option>
          ))}
        </select>
      </View>
    </>
  );
};

export default ModalDropDown;
